import { Stack, Typography, Pagination, useTheme } from "@mui/material";

interface TablesPaginationTypes {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: any;
  onRowsPerPageChange?: any;
  totalElements: any;
  totalPages: any;
}

const FancyTablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  totalElements,
  totalPages,
}: TablesPaginationTypes) => {
  const theme = useTheme();
  const initRow = page * (rowsPerPage ?? 0);
  const finalRow = initRow + (rowsPerPage ?? 0);
  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems="center"
      justifyContent="space-between"
      sx={{ pt: 6 }}
      width="100%"
    >
      <Typography
        variant="body1"
        color="initial"
        sx={{
          fontFamily: "D-dinExp",
          color: theme.palette.greyDue.light,
          fontSize: "1rem",
          mb: {
            xs: 6,
            md: 0,
          },
          width: {
            xs: "100%",
            md: "50%",
          },
        }}
      >
        Mostrando {initRow + 1} a {finalRow ?? 0} de {totalElements ?? 0}{" "}
        registros
      </Typography>
      <Pagination
        count={totalPages}
        color="primary"
        onChange={onPageChange}
        showFirstButton
        showLastButton
        page={page + 1}
      />
    </Stack>
  );
};

export default FancyTablePagination;

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface DialogProps {
  textChildren?: string;
  actionButton?: any;
}

const ConfirmChangeState = ({ textChildren, actionButton }: DialogProps) => {
  return (
    <>
      <DialogTitle>¿Estás seguro de cambiar de estado?</DialogTitle>
      <DialogContent>
        <DialogContentText>{textChildren}</DialogContentText>
      </DialogContent>
      <DialogActions>{actionButton}</DialogActions>
    </>
  );
};

export default ConfirmChangeState;

import { properties } from "../../utils/Properties_es";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { TrashIcon } from "../svgicons/SvgIcons";

interface DialogProps {
  textChildren?: string;
  actionButton?: any;
}

const DeleteModal = ({ textChildren, actionButton }: DialogProps) => {
  return (
    <>
      <DialogTitle>
        <TrashIcon sx={{ fontSize: "1rem", width: 16, color: "#3e627c" }} />
        {properties.com_parval_label_modal_delete_title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro de eliminar <strong>{textChildren}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>{actionButton}</DialogActions>
    </>
  );
};

export default DeleteModal;

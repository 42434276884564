import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogTitle,
  styled,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PdfView from "./pdf/PdfView";
import { getRequestImageDocumentClient } from "../../services/ManagementClientsService";
import { getRequestImageNoteClient } from "../../services/ObservationsService";
import { useApp } from "../../hooks/useApp";
import Url from "../../urls/Url";

const Image = styled("img")(() => ({
  maxWidth: "600px",
  margin: "0 auto",
  display: "block",
}));

interface DialogProps {
  document: any;
  cancelModal: any;
}

const FileViewModal = ({ document, cancelModal }: DialogProps, props: any) => {
  const { setErrorMsg } = useApp();
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchImage(document.id, document.nameD);
    };
    dataInit();
  }, []);

  const handleFetchImage = async (id: any, name?: any) => {
    try {
      let documentByClient: any = null;
      if (document?.service === "client") {
        documentByClient = await getRequestImageNoteClient(id);
      } else {
        documentByClient = await getRequestImageDocumentClient(id, name);
      }

      if (documentByClient) {
        setImage(documentByClient);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  return (
    <>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={cancelModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 8 }}>
        {document?.ext === "docx" ||
        document?.ext === "doc" ||
        document?.ext === "xlsx" ? (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${Url.base_url}/v1/maintenance/documents/download/${document.id}/?originalName=${document.nameD}`}
            width="100%"
            height="800px"
          ></iframe>
        ) : document?.ext === "pdf" ? (
          <PdfView
            pdf={`${Url.base_url}/v1/maintenance/documents/download/${document.id}/`}
          />
        ) : document?.ext === "jpg" ||
          document?.ext === "jpeg" ||
          document?.ext === "png" ? (
          image && <Image src={image} /* ref={imageRef} */ />
        ) : (
          <Typography variant="body1">
            Archivo no soportado por el visor
          </Typography>
        )}
      </DialogContent>
    </>
  );
};

export default FileViewModal;

import { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ResourceAccess from "./security/ResourceAccess";
import { useStep } from "../hooks/useStep";
import { useApp } from "../hooks/useApp";
import { searchAuthority } from "../utils/UtilsFunctions";

// Variable global para mantener el estado de la pestaña seleccionada
let selectedTab: any;

function ariaProps(person: string) {
  return {
    id: `tab-${person}`,
    "aria-controls": `tabpanel-${person}`,
  };
}

const TabsCustomerHome = () => {
  const url = useLocation();
  const { handleReturn } = useStep();
  const { authInfo } = useApp();

  // Función para guardar la pestaña seleccionada en la variable global
  const saveTab = (tab: any) => {
    selectedTab = tab;
    localStorage.setItem("activeTabHomeDue", tab);
  };

  // Función para obtener la pestaña seleccionada de la variable global
  const getSavedTab = () => {
    return selectedTab;
  };

  useEffect(() => {
    // Al montar el componente, intenta obtener el valor de la pestaña desde la variable global
    const savedTab = getSavedTab();
    if (savedTab !== null) {
      selectedTab = savedTab;
    } else {
      // Si no se ha guardado ninguna pestaña, utiliza la pestaña por defecto
      selectedTab = determineTabBasedOnLocation(url.pathname);
    }
    //console.log("savedTab", savedTab);
    //console.log("selectedTab", selectedTab);
  }, [url.pathname]);

  const determineTabBasedOnLocation = (location: any) => {
    if (location === "/") {
      return 0;
    } else if (location === "/bulk-upload-customer-docs") {
      return 1;
    } else if (location === "/legalregistry") {
      return 2;
    }
  };

  const handleChangeTab = (event: any, newValue: any) => {
    // Guarda el valor de la pestaña seleccionada en la variable global
    selectedTab = newValue;
    saveTab(newValue);
    determineTabBasedOnLocation(url.pathname);
  };

  //console.log("selectedTab: ", selectedTab);
  //console.log("activeTabHomeDue: ", localStorage.getItem("activeTabHomeDue"));

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
      <Tabs
        //value={selectedTab ?? 0}
        value={
          typeof localStorage.getItem("activeTabHomeDue") === "string"
            ? Number(localStorage.getItem("activeTabHomeDue"))
            : selectedTab ?? 0
        }
        onChange={handleChangeTab}
        aria-label="customer tabs"
      >
        {ResourceAccess(true, "CUSTOMERTAB:READ") && (
          <Tab
            label="Clientes"
            component={Link}
            to="/"
            {...ariaProps("single-upload-customer-docs")}
          />
        )}
        {ResourceAccess(true, "BULKCUSTOMERLOAD:WRITE") && (
          <Tab
            label="Carga masiva de documentos por cliente"
            component={Link}
            to="/bulk-upload-customer-docs"
            {...ariaProps("bulk-upload-customer-docs")}
          />
        )}
        {/*    {ResourceAccess(true, "TREASURYTAB:READ") && (
          <Tab
            disabled={!ResourceAccess(true, "TREASURYTAB:READ")}
            label="Tesorería"
            component={Link}
            to="/legalregistry"
            {...ariaProps("legalregistry")}
          />
        )} */}
      </Tabs>
    </Box>
  );
};

export default TabsCustomerHome;

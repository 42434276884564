import {
  useState,
  useEffect,
  createContext,
  useMemo,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { requestTypes, branch } from "../types/types";
import { setAuthData } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { getRequestDocumentRole } from "../services/ManagementClientsService";
import {
  getRequestDocumentClient,
  getRequestBranch,
} from "../services/ManagementClientsService";
import { searchAuthority } from "../utils/UtilsFunctions";
import Url from "../urls/Url";
import { getRequestUser } from "../services/UsersService";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
  detailsMsg: "",
  dataBranch: null,
  usersData: null,
};

const AppContext = createContext<requestTypes>(initialState);

const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [detailsMsg, setDetailsMsg] = useState(initialState.detailsMsg);
  const [dataBranch, setDataBranch] = useState<branch[] | null>(null);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(authData != null);

  const navigate = useNavigate();

  const loginProvider = async (loginData: any) => {
    console.log("entra login provider");
    try {
      //save login data in storage
      const authData = await setAuthData("authDUEClientInfo", loginData);
      console.log(authData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        setAuthenticate(valid);
        setAuthInfo(authData);
        console.log(authData);
        console.log(authData.userRole?.id);

        if (authData.userRole?.id === 25) {
          navigate("/legalregistry", { replace: true });
        } else {
          navigate("/", { replace: true });
        }

        return;
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    //e.preventDefault();
    await setAuthData("authDUEClientInfo", null);
    setAuthenticate(false);
    localStorage.removeItem("activeTabHomeDue");
    //@ts-ignore
    window.location.replace(Url.base_login);
  };

  const handleBranch = async () => {
    try {
      const getBranch: any = await getRequestBranch(0, "");
      if (getBranch) {
        setDataBranch(getBranch.content);
      }
    } catch (error: any) {
      setErrorMsg(error);
    }
  };

  //Descomentar para subir
  /*   useEffect(() => {
    let time = 1800000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []); */

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
    setDetailsMsg("");
  };

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role
      );
      console.log(documentByClientByRole);
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      detailsMsg,
      setDetailsMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      handleFetchDocumentsRole,
      dataBranch,
      setDataBranch,
      handleBranch,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      detailsMsg,
      modalData,
      dataBranch,
    ]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;

import React from "react";
import { useApp } from "../../hooks/useApp";

interface Resources {
  isCode: boolean;
  pathOrCode: string;
}

const ResourceAccess = (isCode: boolean, pathOrCode: string) => {
  const { authInfo } = useApp();

  //validate authority
  const authority =
    authInfo?.userRole?.authorityRoles &&
    authInfo?.userRole?.authorityRoles.length > 0
      ? authInfo?.userRole?.authorityRoles.find((a: any) =>
          isCode
            ? a.authority.code === pathOrCode
            : a.authority.path === pathOrCode
        )
      : null;
  if (authority) {
    return true;
  } else {
    return false;
  }
  /* if (authority) {
    return <>{children}</>;
  } else {
    return <></>;
  } */
};

export default ResourceAccess;

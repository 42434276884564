import { URL } from "../utils/UrlConstant";
import { privateFetch } from "../utils/CustomFetch.js";
import { properties } from "../utils/Properties_es";
import { CONSTANT } from "../utils/Constant";

export const getRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CLIENT(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_findid_error);
    });
  return responseData;
};

export const findByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CLIENT(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_findid_error);
    });
  return responseData;
};

export const changeState = async (data) => {
  console.log("data: ", data);
  let responseData = {};
  const params = new URLSearchParams([
    ["clientId", data.clientId],
    ["statusId", data.statusId],
    ["user", data.user],
    ["clientNoteId", data.clientNoteId ?? ""],
  ]);

  let keysParams = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });

  keysParams.forEach((key) => {
    params.delete(key);
  });

  await privateFetch
    .post(
      URL.REQUEST_CHANGE_FLOW,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error al actualizar fase");
    });
  return responseData;
};

export const getStages = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_STAGE, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_findid_error);
    });
  return responseData;
};

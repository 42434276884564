export const convertTo2Digits = (num:number) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date:Date) => {
  return [
    convertTo2Digits(date.getDate()),
    convertTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export const searchAuthority = (isCode: boolean, pathOrCode: string, authInfo:any) => {
  const authority =
    authInfo?.userRole?.authorityRoles &&
    authInfo?.userRole?.authorityRoles.length > 0
      ? authInfo?.userRole?.authorityRoles.find((a: any) =>
          isCode ? a.authority.code === pathOrCode : false
        )
      : false;

  if (authority) {
    return true;
  } else {
    return false;
  }
};
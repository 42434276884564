import { URL } from "../../utils/UrlConstant";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";


export const uploadRequest = async (data:any) => {
  let responseData = {};  
  const formData = new FormData();
  formData.append("file", data?.files?.item(0) as File);
  await privateFetch.post(
    URL.UPLOAD_DOC, data, { 
      headers: {
      ...CONSTANT.HEADER_TYPE3,
      }
    }
  )
  .then(async (response) => {
    responseData = await response.data;
  })
  .catch(async (error) => {
    throw new Error(error.message)    
  });
  return responseData;
}


export const uploadBulkRequest = async (data:any) => {
  let responseData = {};  
  await privateFetch.post(
    URL.UPLOAD_BULK_DOC, data, { 
      headers: {
      ...CONSTANT.HEADER_TYPE4,
      }
    }
  )
  .then(async (response) => {
    responseData = await response.data;
  })
  .catch(async (error) => {
    throw new Error(error.response)    
  });
  return responseData;
}


export const deleteRequest = async (id:string) => {
  let responseData = {};
  await privateFetch
    .delete(URL.DELETED_DOC(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_document_delete_error
      );
    });
  return responseData;
};

export const getDocumentBlank = async (id:string, originalName:string) => {
  let responseData = {};
  await privateFetch
    .get(URL.VIEW_DOCUMENT_BLANK(id, originalName), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error al ver documento");
    });
  return responseData;
};






 


import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  AppBar,
  Stack,
  Link,
  IconButton,
  Toolbar,
  Typography,
  styled,
  Menu,
  MenuItem as MenuItemMui,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  UserIcon,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from "../components/svgicons/SvgIcons";
import theme from "../theme/theme";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import MessageManager from "../components/utils/MessageManager";
import Loading from "../components/utils/Loading";
import { CONSTANT } from "../utils/Constant";
import Banner from "../components/Banner";
import ImageBanner2 from "../resources/images/customer-management.webp";
import Url from "../urls/Url";

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.secondary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const Footer = styled("div")(({ theme }) => ({
  backgroundColor: "rgb(226, 216, 207)",
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(8),
  textAlign: "center",
}));

const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  display: "inline-block",
  marginBottom: "1rem",
}));
const Dashboard = () => {
  const navigate = useNavigate();
  const {
    authInfo,
    authenticate,
    errorMsg,
    successMsg,
    detailsMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
  } = useApp();
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = {
    is_superuser: true,
    is_client: false,
    is_company: false,
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //validate auth
  if (!authenticate) {
    //@ts-ignore
    window.location.replace(Url.base_login);
  }

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details={detailsMsg}
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      <main>
        <AppBar position="relative" sx={{ backgroundColor: "#326267" }}>
          <Banner
            title={`Due Diligence`}
            subTitleWhite={`Gestión de`}
            subTitleYellow={`Clientes`}
            image={ImageBanner2}
            sizeTittle={`2.2125rem`}
            sizeSubTittle={`1.7125rem;`}
          />
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                px: 3,
                minHeight: "54px",
              },
              justifyContent: "right",
              width: "100%",
              maxWidth: "1440px",
              margin: "0 auto",
              position: "absolute",
              left: "0",
              right: "0",
              top: "28%",
              zIndex: "2",
            }}
          >
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    "&:hover, &:focus": {
                      background: "transparent",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <UserIcon />
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.primary.contrastText, ml: 1 }}
                  >
                    {authInfo?.name}
                  </Typography>
                  <ExpandMore sx={{ color: "text.primary" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItemMui onClick={logoutProvider}>
                    {properties.com_parval_label_button_logout_title}
                  </MenuItemMui>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Outlet />
        <Footer>
          <Stack direction="row" justifyContent="center" spacing={3.625}>
            <Link
              href="https://www.facebook.com/ParvalRD/"
              target="_blank"
              color={theme.palette.secondary.dark}
            >
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/parvalrd/"
              target="_blank"
              color={theme.palette.secondary.dark}
            >
              <Instagram />
            </Link>
            <Link
              href="https://www.linkedin.com/company/parval-puesto-de-bolsa/"
              target="_blank"
              color={theme.palette.secondary.dark}
            >
              <Linkedin />
            </Link>
            <Link
              href="https://twitter.com/parval_rd"
              target="_blank"
              color={theme.palette.secondary.dark}
            >
              <Twitter />
            </Link>
          </Stack>
          <LinkText href="mailto:info@parval.com.do">
            info@parval.com.do
          </LinkText>
          <Typography>
            <LinkText href="tel:8095600909" target="_blank">
              809-560-0909
            </LinkText>
            |
            <LinkText href="tel:8094727825" target="_blank">
              809-472-7825
            </LinkText>
          </Typography>
          <Typography color="secondary.dark" variant="body1">
            Copyright 2023. Todos los derechos reservados.
          </Typography>
        </Footer>
      </main>
    </>
  );
};

export default Dashboard;

import { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Autocomplete,
  FormControl,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  FormHelperText,
  Dialog,
  Tooltip,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import InputLabelToForm from "../components/InputLabelToForm";
import { properties } from "../utils/Properties_es";
import { SortertIcon, TrashIcon, Pen } from "../components/svgicons/SvgIcons";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { formatDate } from "../utils/UtilsFunctions";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  getRequest,
  deleteRequest,
  findByIdRequest,
  getRequestBranch,
} from "../services/ManagementClientsService";
import { getRequestUser } from "../services/UsersService";
import { getStages, changeState } from "../services/StageAndFlowService";
import { CONSTANT } from "../utils/Constant";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { searchClientsTypes } from "../types/types";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import DeleteModal from "../components/utils/DeleteModal";
import AddOrUpdateClientModal from "../components/utils/AddOrUpdateClientModal";
import { data as datos } from "../resources/data/data";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTooltip from "../components/utils/FancyTooltip";
import TabsCustomerHome from "../components/TabsCustomerHome";
import DocumentNotification from "../components/utils/DocumentNotificationModal";

const validation = Yup.object().shape({
  startDate: Yup.date().typeError(""),
  endDate: Yup.date().typeError(""),
  code: Yup.string(),
  name: Yup.string(),
  identification: Yup.string(),
  personType: Yup.string().nullable(),
  stage: Yup.string(),
  user: Yup.string(),
});

const ManagementClients: FC = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    handleBranch,
    dataBranch,
    setDataBranch,
  } = useApp();
  const { setActiveStep } = useStep();
  const theme = useTheme();
  registerLocale("es", es);
  const navigate = useNavigate();

  const [managementData, setManagementData] = useState<any>([]);
  const [stage, setStage] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [usersData, setUsersData] = useState<any>(null);

  const defaultValues: searchClientsTypes = {
    startDate: new Date("01/01/2023"),
    endDate: new Date(),
    code: "",
    name: "",
    identification: "",
    personType: "",
    stage: "",
    user: "",
    clientType: "DueDiligence",
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    reset,
  } = useForm<searchClientsTypes>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors } = formState;
  const watch_personType: any = watch("personType");
  const [form, setForm] = useState<any>("");
  const [preFilter, setPreFilter] = useState(defaultValues);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, defaultValues);
      await handleFetchUser();
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de limpiar filtro de busqueda
   */
  const handleClearFilter = async (e: any) => {
    await handleFetchData(0, defaultValues);
    setPage(0);
    reset(defaultValues);
    setPreFilter(defaultValues);

    resetField("personType", { defaultValue: "" });
  };

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filter: any) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequest(currentPage, filter);
      let stageData = await getStages();

      if (data) {
        setManagementData(data);
      }
      if (stageData) {
        setStage(stageData);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchUser = async () => {
    try {
      let users: any = null;
      users = await getRequestUser(0, "Ejecutivo");
      if (users) {
        let usersFilter = await users.content.filter(
          (user: any) =>
            user.status.toUpperCase() === "ACTIVE" &&
            user.username !== "mariacolmenares25@gmail.com" &&
            user.username !== "anami.vip@gmail.com" /* &&
            (user.userRole === "Ejecutivo" || user.userRole === "Administrador") */
        );
        setUsersData(usersFilter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let clientData = await findByIdRequest(id);
      //@ts-ignore
      if (clientData && clientData?.content?.id > 0) {
        //@ts-ignore
        setForm(clientData.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      console.log(data);
      let updateData = await changeState({
        clientId: data.id,
        statusId: data.status,
        user: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0, defaultValues);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Evento de apertura de modal
   * @param event
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "notificationDocuments") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = managementData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setForm(defaultValues);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  /**
   * Paginacion de registros
   */
  const handleChangePage = async (event: unknown, newPage: number) => {
    let customPage = newPage - 1;
    if (customPage !== page) {
      //await handleFetchData(customPage, defaultValues);
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest({
        id: id,
        user: authInfo.username,
      });
      if (
        !deleteData /* || deleteData?.info !== CONSTANT.STATUS_INFO_DELETE */
      ) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0, defaultValues);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const searchAuthority = (isCode: boolean, pathOrCode: string) => {
    const authority =
      authInfo?.userRole?.authorityRoles &&
      authInfo?.userRole?.authorityRoles.length > 0
        ? authInfo?.userRole?.authorityRoles.find((a: any) =>
            isCode ? a.authority.code === pathOrCode : false
          )
        : false;

    if (authority) {
      return true;
    } else {
      return false;
    }
  };

  const handleNavigateClient = async (event: any) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute("data-id");
    const stage = Number(event.currentTarget.getAttribute("data-stage"));
    const status = Number(event.currentTarget.getAttribute("data-status"));
    const noUpd = event.currentTarget.getAttribute("data-risknotupd");

    if (stage === 6 && status === 6) {
      navigate(`/createdClient/${id}`);
    } else if (
      (stage === 9 && status === 1) ||
      (stage === 7 && status === 11 && noUpd === "false")
    ) {
      navigate(`/updatedDueClient/${id}`);
    } else {
      if (authInfo?.userRole?.id === 24 && stage > 8) {
        setActiveStep && setActiveStep(3);
      }
      if (authInfo?.userRole?.id === 11 && stage > 5) {
        setActiveStep && setActiveStep(3);
      } else {
        if (searchAuthority(true, "CREATIONSTEP:READ")) {
          setActiveStep && setActiveStep(3);
        } else if (searchAuthority(true, "QUALIFYSTEP:READ")) {
          setActiveStep && setActiveStep(2);
        } else if (searchAuthority(true, "COMPLIANCESTEP:READ")) {
          setActiveStep && setActiveStep(1);
        } else {
          setActiveStep && setActiveStep(0);
        }
      }

      navigate(`/createClient/${id}`);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        await handleFetchData(0, data);
        break;
    }
    if (modalData?.modalType !== "delete") {
      setForm(form);
    }
  };

  return (
    <>
      <Box sx={{ px: 3.75, py: 12, mx: "auto" }} maxWidth="xl">
        <TabsCustomerHome />
        <Paper
          sx={{ padding: theme.spacing(10), borderRadius: theme.spacing(4) }}
        >
          <form>
            <Grid container spacing={4} sx={{ pb: 8 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
                  color="greyDue.dark"
                >
                  Filtrar por:
                </Typography>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="personType"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={datos.personType}
                      getOptionLabel={(option: any) => option}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de persona"
                          variant="standard"
                          error={!!errors.personType}
                          helperText={
                            errors.personType && errors.personType.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"identification"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={watch_personType === "Física" ? "Cédula" : "RNC"}
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("identification")}
                      error={
                        errors.identification && Boolean(errors.identification)
                      }
                      helperText={
                        errors.identification && errors.identification.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"code"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Código de LA"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("code")}
                      error={errors.code && Boolean(errors.code)}
                      helperText={errors.code && errors.code.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"name"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Nombre"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("name")}
                      error={errors.name && Boolean(errors.name)}
                      helperText={errors.name && errors.name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"startDate"}
                  control={control}
                  defaultValue={new Date()}
                  render={({ field, ...props }) => (
                    <FormControl fullWidth sx={{ padding: "3px 0 0px" }}>
                      <InputLabelToForm
                        error={errors.startDate && Boolean(errors.startDate)}
                      >
                        Fecha desde
                      </InputLabelToForm>
                      <DatePicker
                        locale="es"
                        minDate={
                          new Date(
                            "Thu Jan 01 2023 20:35:30 GMT-0400 (Venezuela Time)"
                          )
                        }
                        maxDate={new Date()}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        dateFormat={CONSTANT.DATE_FORMAT}
                      />
                    </FormControl>
                  )}
                  {...(errors.startDate && (
                    <FormHelperText>{errors.startDate.message}</FormHelperText>
                  ))}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"endDate"}
                  control={control}
                  defaultValue={new Date()}
                  render={({ field, ...props }) => (
                    <FormControl fullWidth sx={{ padding: "3px 0 0px" }}>
                      <InputLabelToForm
                        error={errors.endDate && Boolean(errors.endDate)}
                      >
                        Fecha hasta
                      </InputLabelToForm>
                      <DatePicker
                        locale="es"
                        minDate={
                          new Date(
                            "Thu Jan 13 2023 20:35:30 GMT-0400 (Venezuela Time)"
                          )
                        }
                        maxDate={new Date()}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        dateFormat={CONSTANT.DATE_FORMAT}
                      />
                    </FormControl>
                  )}
                  {...(errors.endDate && (
                    <FormHelperText>{errors.endDate.message}</FormHelperText>
                  ))}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="stage"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Fase</InputLabel>
                      <Select value={value} {...register("stage")}>
                        <MenuItem value="">Seleccione</MenuItem>
                        {stage?.map(
                          (s: any) =>
                            s.id < 10 && (
                              <MenuItem value={s.id} key={s.id}>
                                {s.name.replace("Fase", "")}{" "}
                                {s.id === 7 && `- ${s.flow.name}`}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="user"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Ejecutivo</InputLabel>
                      <Select value={value} {...register("user")}>
                        <MenuItem value="">Seleccione</MenuItem>
                        {usersData !== null &&
                          usersData?.map((user: any, index: number) => (
                            <MenuItem value={user.username} key={index}>
                              {user.username}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
                  <Button
                    variant="contained"
                    //onClick={handleApplyFilter}
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                  >
                    Buscar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClearFilter}
                  >
                    Limpiar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: 4, mb: 6 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/createClient`)}
              disabled={
                ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") === false
              }
            >
              Agregar
            </Button>
          </Stack>
          <Stack sx={{ width: "100%", overflowX: "scroll", maxWidth: "100%" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell align="right">Nombre</TableCell>
                  <TableCell align="right">Identificación</TableCell>
                  <TableCell align="right">Tipo de Persona</TableCell>
                  <TableCell align="right">Fecha de Creación</TableCell>
                  <TableCell align="right">Ejecutivo</TableCell>
                  <TableCell align="right">Fase</TableCell>
                  <TableCell align="right">Estado</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {managementData &&
                  managementData.content &&
                  managementData.content.map((row: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      data-algo={index}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.code}</TableCell>
                      <TableCell align="right">
                        {row.accounts[0]?.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.accounts[0]?.identification}
                      </TableCell>
                      <TableCell align="right">{row.personType}</TableCell>
                      <TableCell align="right">
                        {formatDate(new Date(row.creationDate))}
                      </TableCell>
                      <TableCell align="right">
                        {row.executive?.firstName ?? ""}{" "}
                        {row.executive?.lastName ?? ""}
                      </TableCell>
                      <TableCell align="right">
                        <FancyTooltip
                          title={
                            row.stage.id === 6 ||
                            row.stage.id === 7 ||
                            row.stage.id === 8 ||
                            row.stage.id === 9 ||
                            (row.stage.id === 1 && row.risk === "true") ||
                            row?.preStageRejected?.id > 7
                              ? "Cliente en actualización"
                              : ""
                          }
                          placement="top"
                        >
                          <span style={{ cursor: "default" }}>
                            {row.stage.name}
                          </span>
                        </FancyTooltip>
                      </TableCell>
                      <TableCell align="right">
                        {row.status.name !== "undefined" ? (
                          row.status.id === 3 ||
                          row.status.id === 9 ||
                          row.status.id === 10 ||
                          //row.status.id === 11 ||
                          (row.status.id === 1 && row.stage.id !== 9) ? (
                            <Button
                              variant="contained"
                              sx={{
                                background: "#5271ff",
                                borderColor: "#5271ff!important",
                                fontSize: "0.65rem",
                                letterSpacing: "0.25px",
                              }}
                            >
                              {row.status.name}
                            </Button>
                          ) : row.status.id === 2 ||
                            row.status.id === 4 ||
                            row.status.id === 5 ||
                            row.status.id === 7 ? (
                            <Button
                              variant="contained"
                              sx={{
                                background: "#d32f2f",
                                borderColor: "#d32f2f!important",
                                fontSize: "0.65rem",
                                letterSpacing: "0.25px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.status.name}
                            </Button>
                          ) : row.stage.id === 7 &&
                            row.status.id === 11 &&
                            row.risk === "true" ? (
                            <Button
                              variant="contained"
                              sx={{
                                background: "#5271ff",
                                borderColor: "#5271ff!important",
                                fontSize: "0.7rem",
                                letterSpacing: "0.25px",
                              }}
                            >
                              EN ANÁLISIS
                            </Button>
                          ) : /* ) : row.stage.id === 9 &&
                            (row.status.id === 1 ||
                              row.status.id === 8 ||
                              row.status.id === 11) ? ( */
                          (row.stage.id === 7 &&
                              row.status.id === 11 &&
                              (row.risk === null || row.risk === "false")) ||
                            (row.stage.id === 9 && row.status.id === 1) ? (
                            <Button
                              variant="contained"
                              sx={{
                                background: "#06833c",
                                borderColor: "#06833c!important",
                                fontSize: "0.7rem",
                                letterSpacing: "0.25px",
                              }}
                            >
                              ACTUALIZADO -{" "}
                              {new Date(row.modificationDate).getFullYear()}
                            </Button>
                          ) : (
                            row.stage.id === 6 &&
                            row.status.id === 6 && (
                              <Button
                                variant="contained"
                                sx={{
                                  background: "#06833c",
                                  borderColor: "#06833c!important",
                                  fontSize: "0.7rem",
                                  letterSpacing: "0.25px",
                                }}
                              >
                                {row.status.name} -{" "}
                                {new Date(row.modificationDate).getFullYear()}
                              </Button>
                            )
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Cambiar Estado" placement="top">
                          <IconButton
                            aria-label="sorter"
                            component="label"
                            data-id={row.id}
                            sx={{ color: "#06833c" }}
                            onClick={handleOpenModal}
                            data-name="update"
                            disabled={
                              ResourceAccess(true, "CHANGESTAGE:WRITE") ===
                              false
                            }
                          >
                            <SortertIcon />
                          </IconButton>
                        </Tooltip>
                        {(authInfo.userRole.id === 9 ||
                          authInfo.userRole.id === 10) && (
                          <Tooltip
                            title="Notificación de documentos"
                            placement="top"
                          >
                            <IconButton
                              aria-label="sorter"
                              component="label"
                              data-id={row.id}
                              sx={{ color: theme.palette.primary.main }}
                              disabled={
                                row.fileForm !== "Físico" ||
                                (authInfo.userRole.id === 9 &&
                                  row.notificationState === 2) ||
                                (authInfo.userRole.id === 9 &&
                                  row.notificationState === 3) ||
                                (authInfo.userRole.id === 10 &&
                                  row.notificationState === 1) ||
                                (authInfo.userRole.id === 10 &&
                                  row.notificationState === 0) ||
                                (authInfo.userRole.id === 10 &&
                                  row.notificationState === 3)
                              }
                              onClick={handleOpenModal}
                              data-name="notificationDocuments"
                            >
                              <MarkUnreadChatAltIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            sx={{ color: "#5271ff" }}
                            data-id={row.id}
                            data-stage={row.stage.id}
                            data-status={row.status.id}
                            data-risknotupd={row.risk === null ? false : true}
                            onClick={handleNavigateClient}
                          >
                            <Pen />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar" placement="top">
                          <IconButton
                            color="success"
                            aria-label="trash"
                            component="label"
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                            disabled={
                              !ResourceAccess(
                                true,
                                "DELETECUSTOMERBUTTON:WRITE"
                              ) || row.stage.id > 5
                            }
                          >
                            <TrashIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                {(!managementData ||
                  !managementData.content ||
                  managementData.content.length <= 0) && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      {properties.com_parval_label_table_empty}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Stack>
          <FancyTablePagination
            count={
              managementData?.content?.length > 0
                ? managementData?.content?.length
                : 0
            }
            rowsPerPage={managementData.size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalElements={managementData.totalElements}
            totalPages={managementData.totalPages}
          />
        </Paper>
      </Box>
      {modalData?.modalType === "update" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateClientModal
            form={form}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            stage={stage}
          />
        </Dialog>
      )}
      {modalData?.modalType === "notificationDocuments" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DocumentNotification
            client={form}
            cancelModal={handleCancelModal}
            handleFetchData={handleFetchData}
            defaultValuesFilter={defaultValues}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            textChildren={`cliente`}
            //data={modalData?.modalObject?.id}            }
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementClients;

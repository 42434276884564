import localforage from "localforage";
import jwt_decode from "jwt-decode";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
/**
 * Set Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const setAuthData = async (keyName:string, authData:any) => {
  try {
    await setItem(keyName, authData);
    const value:any = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

let isMounted:any = true
const removeAuthData = () => {
  isMounted = false
  console.log('remove data' + isMounted)
};


/**
 * Get Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const getAuthData = async () => {
 // return await getItem("authDUEClientInfo");
  let authData:any = null;
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionCode = urlParams.get("sc");
    const email = urlParams.get("email");

    //if exists param - validate sc and email
    if (sessionCode && email) {
      //obtener token
      const loginData = await SVSessionValidateRequest(email, sessionCode);
      //set loginData in localstorage
      authData = await setAuthData("authDUEClientInfo", loginData);
      
      if (authData) {
        if (authData.userRole?.id === 25) {
          window.location.href = "/legalregistry";
        } else {
          window.location.href = "/";
        }
      }
      
    } else {
      authData = await getItem("authDUEClientInfo");

      //validate authData
      if (authData  && authData?.tokenInfo ) {
        const decodeToken:any = jwt_decode(authData?.tokenInfo?.access_token);
        const expiresAt = decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt; 
        
        if (!valid) {    
          authData = null;
          
        }
      }
    }
  } catch (error) {
    console.log(error);
    //removeAuthData
    authData = null;
    //window.location.replace(Url.base_login);
  }

  return authData;
};

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 */
export const setItem = async (key:string, value:any) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion");
  });
};

/**
 * busca un item al store
 *
 * @param key
 */
export const getItem = async (key:any) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion");
  });
};

/**
 * Elimina un item al store
 *
 * @param key
 */
export const removeItem = async (key:any) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion");
  });
};

import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Autocomplete,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { AngleRightIcon } from "../components/svgicons/SvgIcons";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import { useNote } from "../hooks/useNote";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createClientsTypes } from "../types/types";
import { data } from "../resources/data/data";
import {
  updateRequest,
  getRequestDocumentClient,
} from "../services/ManagementClientsService";
import { changeState } from "../services/StageAndFlowService";
import { properties } from "../utils/Properties_es";
import ExecutiveStage from "./stage/ExecutiveStage";
import ComplianceStage from "./stage/ComplianceStage";
import ConfirmChangeState from "../components/utils/ConfirmChangeState";
import Observations from "../components/utils/Observations";
import AddObservation from "../components/utils/AddObservation";
import {
  clientSchema,
  executiveSchema,
  branchSchema,
} from "../validations/Validations";
import DocumentStage from "./stage/DocumentsStage";
import ResourceAccess from "../components/security/ResourceAccess";

const CreateClientCompliance: FC = () => {
  const theme = useTheme();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
  } = useApp();
  const { handleAddNotes } = useNote();
  const {
    setActiveStep,
    handleReturn,
    client,
    handleFetchClient,
    usersData,
    dataBranch,
  } = useStep();
  const { id } = useParams();
  const [documentByClient, setDocumentByClient] = useState<any | null>(null);
  const [documentByClientAnalysis, setDocumentByClientAnalysis] = useState<
    any | null
  >(null);
  const [documentPrevStage, setDocumentPrevStage] = useState<any | null>(null);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);

  useLayoutEffect(() => {
    const dataInit = async () => {
      if (id) {
        handleFetchClient && handleFetchClient(id);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role,
        signatureType
      );
      if (documentByClientByRole) {
        return documentByClientByRole;
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const defaultValues: createClientsTypes = {
    id: client?.id ? client.id : "",
    code: client?.code ? client.code : "",
    creationtDate: new Date(),
    creatorUser: client?.creatorUser ? client.creatorUser : authInfo?.username,
    executive: client?.executive ?? [],
    modificationDate: new Date(),
    modifierUser: client?.modifierUser
      ? client.modifierUser
      : authInfo?.username,
    personType: client?.personType ? client.personType : "Física",
    status: client?.status ? client.status : "",
    stage: client?.stage ? client.stage : "Identificación",
    office: client?.office ?? [],
    typeAccount: client?.typeAccount ? client.typeAccount : "",
    fileForm: client?.fileForm ? client.fileForm : "",
    clientStatus: client?.clientStatus ? client.clientStatus : "1",
    accounts: (client?.accounts && client.accounts) ?? [
      {
        client: 0,
        name: "",
        identification: "",
        email: "",
        risk: "",
      },
    ],
  };
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    resetField,
  } = useForm<createClientsTypes>({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const { errors, isDirty } = formState;
  const { fields: addAccountsField } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 2,
    },
  });
  const [requiredDocuments, setRequiredDocuments] = useState(false);
  const [riskMore, setriskMore] = useState(null);
  const watch_personType: any = watch("personType");
  const watch_riskType: any = watch("accounts");
  const watch_signatureType: any = watch("fileForm");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    if (watch_personType && isDirty) {
      resetField("typeAccount", {
        defaultValue: "",
      });
    }
    // eslint-disable-next-line
  }, [watch_personType]);

  useEffect(() => {
    let riskMoreTall: any = null;
    watch_riskType.filter((c: any) => {
      if (c.risk.toLowerCase() === data.risk[3].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[2].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[1].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else {
        riskMoreTall = c;
        return true;
      }
    });
    riskMoreTall !== null &&
      riskMoreTall?.id > 0 &&
      setriskMore(riskMoreTall.risk);
    const dataDocuments = async () => {
      if (id && riskMoreTall !== null && riskMoreTall?.id > 0) {
        const documentCurrentStage = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          10,
          watch_signatureType
        );
        setDocumentByClient(documentCurrentStage);
        const documentIdentification = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          9,
          watch_signatureType
        );
        setDocumentPrevStage(documentIdentification);
        const loadDocumentsAnalysis = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          24,
          watch_signatureType
        );
        setDocumentByClientAnalysis(loadDocumentsAnalysis);
      }
    };
    dataDocuments();
    // eslint-disable-next-line
  }, [watch_personType, watch_riskType]);

  const handleUpdateClientData = async (data: any) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        id: id,
        modifierUser: authInfo?.username,
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      if (id) {
        handleFetchClient && handleFetchClient(id);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangeState = async (data: any, actionState: string) => {
    let changeStateData: any = false;
    try {
      if (actionState === "degradedstage") {
        await handleUpdateClientData(client);
        if (id) {
          const results: any = await Promise.all([
            handleAddNotes &&
              handleAddNotes(
                data,
                id,
                "Estado actualizado y observación registrada"
              ),
          ]);
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: client.stage.id === 6 ? 2 : 4,
            //statusId: client.stage.id === 2 || client.stage.id === 6 ? 2 : 4,
            user: authInfo?.username,
            clientNoteId: results[0]?.id ?? null,
          });

          /* if (client?.stage?.id === 6) {
            changeStateData = await changeState({
              clientId: Number(id),
              statusId: 2,
              user: authInfo?.username,
              clientNoteId: results[0]?.id ?? null,
            });
          } else {
            changeStateData = await changeState({
              clientId: Number(id),
              statusId: client.stage.id === 2 ? 2 : 4,
              user: authInfo?.username,
              clientNoteId: results[0]?.id ?? null,
            });
          } */
        }
      } else if (actionState === "updatestage") {
        if (
          documentByClient &&
          documentByClient.some(
            (d: any) =>
              (client.personType === "Física" &&
                d.requiredPhysical === true &&
                d.uploaded < 1) ||
              (client.personType === "Jurídica" &&
                d.requiredJuridic === true &&
                d.uploaded < 1)
          )
        ) {
          setErrorMsg &&
            setErrorMsg("Hay algunos documentos requeridos vacíos");
          setRequiredDocuments(true);
        } else {
          await handleUpdateClientData(client);
          if (client?.stage?.id === 2 || client?.stage?.id === 3) {
            changeStateData = await changeState({
              clientId: Number(id),
              statusId: 10,
              //statusId: client.stage.id === 2 ? 9 : 10,
              user: authInfo?.username,
            });
          } else {
            changeStateData = await changeState({
              clientId: Number(id),
              statusId: client?.stage?.id === 6 ? 11 : 3,
              user: authInfo?.username,
            });
          }

          if (!changeStateData) {
            setErrorMsg && setErrorMsg("Error al actualizar fase");
            setLoading && setLoading(false);
            return;
          }
        }
      }
      if (changeStateData !== false) {
        setLoading && setLoading(false);
        setSuccessMsg && setSuccessMsg("Estado actualizado");
        if (id && actionState === "updatestage") {
          handleFetchClient && handleFetchClient(id);
        }
        if (
          actionState === "updatestage" &&
          /* client?.stage?.id === 3 ||  */ client?.stage?.id === 6
        ) {
          setActiveStep &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (actionState === "degradedstage") {
          if (id) {
            handleFetchClient && handleFetchClient(id);
          }
        }
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleOpenModal = async (event: any /* name: string */) => {
    event.preventDefault();

    const modalAction = event.currentTarget.getAttribute("data-name");
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: null,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleCloseNotes = () => {
    setOpenNotesDialog(false);
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const typeAction = e.target.name || modalData?.modalType;
    switch (typeAction) {
      case "update":
        await handleUpdateClientData(data);
        break;
      case "updatestage":
        await handleChangeState(data, "updatestage");
        break;
      case "degradedstage":
        await handleChangeState(data, "degradedstage");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <form>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
            Crear cliente
            <br />
            <small style={{ fontSize: "1rem" }}>
              <b>Creado por:</b> {client?.creatorUser}
            </small>
            <br />
            <small style={{ fontSize: "1rem" }}>
              <b>Modificado por:</b> {client?.modifierUser}
            </small>
          </Typography>
          <Typography variant="h5" color="greyDue.dark" sx={{ mb: 4 }}>
            <strong>{client?.stage?.name}</strong> /{" "}
            <small>{client?.flow?.name}</small>
          </Typography>
        </Stack>
        <Grid container spacing={6} maxWidth="lg" alignItems="flex-end">
          <Grid item md={6} sm={4} xs={12}>
            <Typography
              variant="h5"
              color="greyDue.dark"
              sx={{ fontWeight: 400, fontSize: "1.25rem" }}
            >
              <AngleRightIcon
                sx={{
                  marginRight: "0px",
                  color: "secondary.main",
                  transform: "translateY(3px)",
                  fontSize: "1.25rem",
                }}
              />{" "}
              Datos del cliente
            </Typography>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              control={control}
              name="office"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    dataBranch ?? [
                      {
                        address: "",
                        id: 0,
                        name: "",
                        notaryId: 0,
                        tableName: "",
                        type: "",
                      },
                    ]
                  }
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  sx={{ pb: 0 }}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Sucursal"
                        variant="standard"
                        error={!!errors.office}
                        helperText={errors.office && errors.office.message}
                        disabled
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              name={"clientStatus"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Tipo de cliente</InputLabel>
                  <Select
                    value={value}
                    {...register("clientStatus")}
                    disabled={id ? true : false}
                  >
                    <MenuItem value="">Seleccione</MenuItem>
                    <MenuItem value="1">Cliente nuevo</MenuItem>
                    <MenuItem value="7">Cliente actualización</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={6} maxWidth="lg">
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"code"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Código del cliente"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  disabled
                  {...register("code")}
                  error={errors.code && Boolean(errors.code)}
                  helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              control={control}
              name="executive"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    usersData ?? [
                      {
                        id: 0,
                        username: "",
                        firstName: "",
                        lastName: "",
                        phone: 0,
                        status: "",
                        userRole: "",
                        activationCode: "",
                        userBranchOffice: "",
                      },
                    ]
                  }
                  getOptionLabel={(option: any) =>
                    usersData && option.firstName
                      ? option.firstName + " " + option.lastName
                      : ""
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value ?? []}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  sx={{ pb: 0 }}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Ejecutivo"
                        variant="standard"
                        error={!!errors.office}
                        helperText={errors.office && errors.office.message}
                        disabled
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="personType"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={data.personType}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de persona"
                        variant="standard"
                        error={!!errors.personType}
                        helperText={
                          errors.personType && errors.personType.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="fileForm"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={data.fileForm}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Modalidad de Firma"
                        variant="standard"
                        error={!!errors.fileForm}
                        helperText={errors.fileForm && errors.fileForm.message}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="typeAccount"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    watch_personType === "Física"
                      ? data.typeAccount
                      : data.typeAccountLegal
                  }
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de cuenta"
                        variant="standard"
                        error={!!errors.typeAccount}
                        helperText={
                          errors.typeAccount && errors.typeAccount.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {addAccountsField.map((field, index) => {
          return (
            <div key={index}>
              <Divider sx={{ pt: 3, mb: 3 }} />
              <Grid container spacing={6} maxWidth="lg">
                <Grid item md={3} sm={6} xs={12}>
                  <Controller
                    name={`accounts.${index}.identification`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={watch_personType === "Física" ? `Cédula` : `RNC`}
                        type="text"
                        variant="standard"
                        fullWidth
                        value={value}
                        disabled
                        {...register(
                          `accounts.${index}.identification` as const
                        )}
                        error={
                          errors?.accounts?.[index]?.identification && true
                        }
                        helperText={
                          errors?.accounts?.[index]?.identification?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Controller
                    name={`accounts.${index}.name`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Nombres y Apellidos"
                        type="text"
                        variant="standard"
                        fullWidth
                        value={value}
                        disabled
                        {...register(`accounts.${index}.name` as const)}
                        error={errors?.accounts?.[index]?.name && true}
                        helperText={errors?.accounts?.[index]?.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Controller
                    name={`accounts.${index}.email`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Email"
                        type="mail"
                        variant="standard"
                        fullWidth
                        value={value}
                        disabled
                        {...register(`accounts.${index}.email` as const)}
                        error={errors?.accounts?.[index]?.email && true}
                        helperText={errors?.accounts?.[index]?.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ display: "flex" }}>
                  <Controller
                    control={control}
                    name={`accounts.${index}.risk`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        fullWidth
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={data.risk}
                        getOptionLabel={(option: any) => option || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option === value
                        }
                        //@ts-ignore
                        value={value}
                        disabled
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Riesgo"
                              variant="standard"
                              error={errors?.accounts?.[index]?.risk && true}
                              helperText={
                                errors?.accounts?.[index]?.risk?.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          );
        })}

        <Grid container spacing={6} maxWidth="lg" sx={{ pt: 6, pb: 8 }}>
          {id && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                data-name="observation"
                onClick={() => setOpenNotesDialog(true)}
              >
                Ver observaciones
              </Button>
            </Grid>
          )}
        </Grid>
        {!id && (
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 4 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReturn}
            >
              Regresar
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mr: 2,
              }}
              data-name="create"
              name="create"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </Stack>
        )}
      </form>
      {id && (
        <>
          {ResourceAccess(true, "COMPLIANCE_DOCUMENTS:READ") === true && (
            <>
              <ComplianceStage
                personTypeData={watch_personType}
                riskTypeData={riskMore}
                documentByClient={documentByClient}
                handleFetchDocumentsRole={handleFetchDocumentsRole}
                handleFetchClient={handleFetchClient}
                client={client}
                requiredDocuments={requiredDocuments}
                disabledByStage={
                  ResourceAccess(true, "COMPLIANCESTEP:WRITE") === false ||
                  client?.stage.id === 5
                }
              />
              <Divider
                sx={{
                  mb: theme.spacing(16),
                  borderColor: theme.palette.secondary.dark,
                }}
              />
            </>
          )}

          <ExecutiveStage
            personTypeData={watch_personType}
            riskTypeData={riskMore}
            documentByClient={documentPrevStage}
            handleFetchDocumentsRole={handleFetchDocumentsRole}
            handleFetchClient={handleFetchClient}
            client={client}
            requiredDocuments={requiredDocuments}
            title="Documentos de identificación"
            disabledByStage={true}
          />
          <DocumentStage
            personTypeData={watch_personType}
            riskTypeData={riskMore}
            documentByClient={documentByClientAnalysis}
            handleFetchDocumentsRole={handleFetchDocumentsRole}
            handleFetchClient={handleFetchClient}
            client={client}
            requiredDocuments={requiredDocuments}
            idRole="24"
            title="Categoría Análisis y Monitoreo"
          />
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 4 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReturn}
            >
              Regresar
            </Button>
            {ResourceAccess(true, "AUTHORITYTOCHANGESTAGEINSTEP:WRITE") &&
              (client?.stage?.id === 2 ||
                client?.stage?.id === 3 ||
                client?.stage?.id === 6 ||
                client?.stage?.id === 8) && (
                <>
                  <Button
                    variant="contained"
                    data-name="degradedstage"
                    type="button"
                    name="degradedstage"
                    disabled={
                      client?.stage?.id === 6 && client?.status?.id === 2
                    }
                    color="primary"
                    onClick={handleOpenModal}
                  >
                    Rechazar
                  </Button>
                  {isDirty && (
                    <Button
                      variant="contained"
                      color="primary"
                      data-name="update"
                      name="update"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar
                    </Button>
                  )}
                  {!isDirty && (
                    <Button
                      variant="contained"
                      data-name="updatestage"
                      name="updatestage"
                      onClick={handleSubmit(onSubmit)}
                      color="primary"
                      disabled={
                        client?.stage?.id === 6 && client?.status?.id === 2
                      }
                    >
                      Guardar y Enviar
                    </Button>
                  )}
                </>
              )}
          </Stack>
        </>
      )}
      {modalData?.modalType === "updatestage" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <ConfirmChangeState
            textChildren="Si cambias de estado no podras agregar o borrar los documentos cargados."
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  name="updatestage"
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
      <Dialog
        open={openNotesDialog}
        onClose={() => setOpenNotesDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <Observations id={id ? id : ""} closeModal={handleCloseNotes} />
      </Dialog>
      {modalData?.modalType === "degradedstage" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddObservation
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            idClient={id ? id : ""}
          />
        </Dialog>
      )}
    </>
  );
};

export default CreateClientCompliance;

import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  ButtonBase,
  styled,
  useTheme,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";

const BoxField = styled(Box)(() => ({
  "& .MuiInputBase-root": {
    marginTop: "0",
    position: "relative",
    overflow: "hidden",
    border: "1px solid #ced4da",
    borderLeft: "0",
    borderRadius: "0 0.375rem 0.375rem 0",
    "& input": {
      paddingBottom: "3px",
      paddingLeft: "5px",
      paddingTop: "0",
      width: "100%",
    },
  },
}));

const Field = styled(TextField)(({ theme }) => ({
  flexBasis: "calc(100% - 155px)",
  marginBottom: "0",
  marginTop: "0",
  "& .MuiFormLabel-root.Mui-disabled": {
    color: theme.palette.text.primary,
    transform: "translate(0, -1.5px) scale(0.75)",
  },
}));

const TypographyField = styled(Typography)(({ theme }) => ({
  background: "#e9ecef",
  padding: "2px 10px",
  borderRadius: ".375rem 0 0 .375rem",
  border: "1px solid #ced4da",
  height: "28px",
  width: "155px",
}));

interface DialogProps {
  onSubmit: any;
  cancelModal: any;
  idClient: string;
}

interface addObservation {
  description: string;
  idClient: string;
  file?: FormData;
}

const AddObservation = ({ onSubmit, cancelModal, idClient }: DialogProps) => {
  const validation = Yup.object().shape({
    description: Yup.string()
      .required("Campo es requerido")
      .max(256, "Descripción no puede ser más de 256 caracteres"),
  });
  const theme = useTheme();
  const defaultValues: addObservation = {
    description: "",
    idClient: idClient,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
  } = useForm<addObservation>({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(validation),
  });
  const watch_file: any = watch("file");
  const [alert, setAlert] = useState("");

  const allowExt: any = ["jpeg", "jpg", "png"];
  useEffect(() => {
    if (watch_file && watch_file[0]?.name) {
      const fileName = watch_file[0].name.split(".").pop();
      const isAllowExt = allowExt.some((ext: any) => ext === fileName);
      if (!isAllowExt) {
        setAlert("Tipo de archivo no permitido. Solo .jpeg, .jpg, .png");
        resetField("file");
        setTimeout(() => {
          setAlert("");
        }, 2000);
        return;
      }
    }
  }, [watch_file]);

  return (
    <>
      <DialogTitle>Agregar observación</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={cancelModal}
        sx={{
          position: "absolute",
          right: 14,
          top: 14,
          color: theme.palette.info.dark,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 4, md: 8 }, pb: 0 }}>
        <form>
          <Grid container spacing={8} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <BoxField>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ position: "relative" }}
                >
                  <TypographyField variant="body1" color="text.primary">
                    Seleccionar archivo
                  </TypographyField>
                  <Field
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    margin="normal"
                    fullWidth
                    disabled
                    value={
                      watch_file && watch_file[0]?.name
                        ? watch_file[0].name
                        : ""
                    }
                  />
                  <ButtonBase
                    component="label"
                    sx={{
                      padding: theme.spacing(2),
                      position: "absolute",
                      width: "calc(100% - 165px)",
                      bottom: "0",
                      height: "100%",
                    }}
                  >
                    <Controller
                      name={"file"}
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <input
                          type="file"
                          {...register("file")}
                          hidden
                          accept={"image/png, image/jpeg, image/jpg"}
                        />
                      )}
                    />
                  </ButtonBase>
                </Stack>
              </BoxField>
              {alert !== "" && <FormHelperText>{alert}</FormHelperText>}
            </Grid>
          </Grid>
          <input
            hidden
            value={idClient}
            {...register("idClient", { required: true })}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          disabled={alert !== ""}
          onClick={handleSubmit(onSubmit)}
        >
          Agregar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddObservation;

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 22/12/2022
 *
 * @description JS para manejo de constantes
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const CONSTANT = {
  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX:"/security",
  DISPATCHER_USER_PREFIX:"/users",
  DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",
  DISPATCHER_PERFIL_INVESTMENT_PREFIX: "/investment-profile",
  DISPATCHER_PERFIL_MAINTENANCE_PREFIX: "/perfil-maintenance",
  //DISPATCHER_MAINTENANCE_PREFIX: "",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

  /** DEPARTMENT */
  DEPARTMENT1_VALUE:"Contabilidad",
  DEPARTMENT2_VALUE:"Operaciones",
  DEPARTMENT3_VALUE:"Servicio al cliente",

  /** PORTAL */
  PORTAL_DD:"DD",
  PORTAL_GL:"GL",
  PORTAL_OP:"OP",

  /** MANAGEMENT STATUS */
  MANAGEMENT_STATUS_SLC:"SLC",
  MANAGEMENT_STATUS_PRC:"PRC",
  MANAGEMENT_STATUS_FIN:"FIN",
  MANAGEMENT_STATUS_FAC:"FAC",
  MANAGEMENT_STATUS_DEL:"DEL",
  
   /** TAX */
   TAX_VALUE:0.18,

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** PHONETYPE */
  CODPHONE: 506,

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  HEADER_TYPE2: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  HEADER_TYPE3: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryAGajSuGLyB3CXIsT",
  },

  HEADER_TYPE4: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },

  AUTH_GRANTTYPE: "password",

  /** REQUEST STATUS INFO */
  STATUS_INFO_ACTIVE: "ACTIVE",
  STATUS_INFO_DELETE: "DELETED",
  STATUS_INFO_UPDATED: "UPDATED",

  DATE_FORMAT: "dd/MM/yyyy",

  /** USER ROLES */
  USER_ADMINISTRATOR_ROLE: "Administrador",
  USER_EDITOR_ROLE: "Editor",
  USER_READER_ROLE: "Lector",

};

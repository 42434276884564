import { CONSTANT } from "./Constant";
export const URL = {

    APPLOGIN_REQUEST: CONSTANT.DISPATCHER_SECURITY_PREFIX + "/oauth/token",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
    /** PARVAL EXTERNAL API */
    CLIENTACCOUNTS_REQUEST: "/user/cuentas",

    /** maintenance urls */
    REQUEST_CERTCATEGORY_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${page}/${size}`,
    REQUEST_CERTCATEGORY: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ''}`,

    REQUEST_CERTIFICATE_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

    REQUEST_CLIENT_LIST: (endDate:any, page:string, size:string, startDate:any, ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/?deleted=false&endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}&sort=creationDate,DESC`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,
    CREATE_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client${id ? "/"+id : ''}`,
    UPLOAD_DOCUMENTS: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/upload`,
    STATE_NOTIFICATION: (clientId: string, state: string, comment?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/state-notification?clientId=${clientId}${comment ? "&comment=" + comment : ''}&state=${state}`,

    /** Branch Office */
    REQUEST_BRANCH: (page:number, filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/branchOffice/${filter !== "" ? "search/?description="+filter : "?page="+page+"&size=100"}&type=J`,

    //https://parval-due-diligence-dispatcher.bitcode-enterprise.dev/v1/maintenance/dashboard/state-notification?clientId=5&comment=as&state=10


    // perfil-investment/

    REQUEST_LIST_INVESTMENT: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/users?page=${page}&size=${size}`,
    REQUEST_INVESTMENT_PROFILE_WITH_ID: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/${id}`,
    REQUEST_INVESTMENT_PROFILE_CALCULATE: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/calculate/${id}`,
    

    /** users urls */
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/?page=${page}&size=${size}`,
    REQUEST_USER_CREATE_ADMIN: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,

    /**Document url */
    GET_CLIENT_DOC: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/`,
    UPLOAD_DOC: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/upload`,
    UPLOAD_BULK_DOC: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/batch/upload`,   
    VIEW_DOCUMENT_BLANK: (id:string, originalName:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/view/${id}/?originalName=${originalName}`,  

    DOWNLOAD_DOC: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/download/${id ? id : ''}/`,
    VIEW_DOWNLOAD_DOC: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/systemname/${id ? id : ''}`,
    DELETED_DOC: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/delete/${id ? id : ''}`,


    /** DocumentRole urls */
    REQUEST_DOCUMENT_ROLE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/`,

    /*Stage url*/
    REQUEST_STAGE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/stage/`,
    REQUEST_CHANGE_FLOW: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/stage/change`,

    /** Obervations url */
    NOTES_BY_RECORD: (id:any, page:number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id}/notes/?page=${page}&size=5`,
    SAVE_NOTES_BY_RECORD: (id:any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id}/notes/`,
    SAVE_NOTES_WITH_IMAGES_BY_RECORD:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/upload/`,
    DELETE_NOTES_BY_RECORD: (id:any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/notes/${id}`,
    DOWNLOAD_NOTES_IMAGE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/download/${id ? id : ''}/`,
    
}
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FancyInputFile from "../../components/FancyInputFile";
import { AngleRightIcon } from "../../components/svgicons/SvgIcons";
import { useApp } from "../../hooks/useApp";
import {
  Grid,
  Typography,
  IconButton,
  useTheme,
  Button,
  Stack,
  Divider,
  InputLabel,
  Dialog,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CirclePlusIcon } from "../../components/svgicons/SvgIcons";
import {
  uploadRequest,
  deleteRequest,
} from "../../services/documents/DocumentsService";
import { properties } from "../../utils/Properties_es";
import DeleteModal from "../../components/utils/DeleteModal";
import FileViewModal from "../../components/utils/FileViewModal";

interface ExecutiveStageType {
  personTypeData: string | null;
  riskTypeData: string | null;
  documentByClient: null;
  handleFetchDocumentsRole: any;
  handleFetchClient: any;
  client: any;
  requiredDocuments: boolean;
  disabledByStage?: boolean;
}

const CreationStage = ({
  personTypeData,
  riskTypeData,
  documentByClient,
  handleFetchDocumentsRole,
  handleFetchClient,
  client,
  requiredDocuments,
  disabledByStage,
}: ExecutiveStageType) => {
  const theme = useTheme();
  const { id } = useParams();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  const [customLoader, setCustomLoader] = useState(false);
  const [documentByClientByRole, setDocumentByClientByRole] = useState<
    any | null
  >(null);
  let newF = { newField: false };
  let objF: any;

  useEffect(() => {
    const dataInit = async () => {
      if (id && riskTypeData !== "") {
        await handleFetchDocuments();
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, [documentByClient]);

  const handleFetchDocuments = async () => {
    //setCustomLoader(true);
    try {
      setDocumentByClientByRole(
        documentByClient &&
          documentByClient !== null &&
          //@ts-ignore
          documentByClient.length > 0 &&
          //@ts-ignore
          documentByClient.map((d: any) => ({ ...d, ...newF }))
      );
    } catch (error: any) {
      setCustomLoader(false);
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    let fileName = data.file[0].name.split(/\.(?=[^.]*$)/);
    fileName[0] =
      fileName[0].replace(/[^A-Z0-9.]+/gi, "_") + Math.round(Date.now() / 1000);
    const formData = new FormData();
    formData.append("file", data.file[0], fileName.join("."));
    for (const key in data) {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    }

    try {
      let createData = await uploadRequest(formData);
      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_save_error);
        setLoading && setLoading(false);
        return;
      }

      id &&
        (await handleFetchDocumentsRole(id, personTypeData, riskTypeData, 10));
      await handleFetchClient(id);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleDelete = async (idDoc: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(idDoc);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      if (id) {
        await handleFetchDocumentsRole(id, personTypeData, null, 10);
        await handleFetchClient(id);
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    const nameD = event.currentTarget.getAttribute("data-name-document");
    const extensionD = event.currentTarget.getAttribute("data-extension");

    if (modalAction === "delete") {
      object = { document: nameD, id: id };
    }

    if (modalAction === "view") {
      object = { id: id, nameD: nameD, ext: extensionD };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const addField = async (e: any) => {
    const nameDoc = e.currentTarget.getAttribute("data-name");
    const newDocumentByClient = documentByClientByRole.map((d: any) => {
      if (d.name !== nameDoc) return d;
      return { ...d, newField: true };
    });
    setDocumentByClientByRole(newDocumentByClient);
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();

    const typeAction = e.target.name;
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);

        break;
      default:
        break;
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        color="greyDue.dark"
        sx={{ fontWeight: 400, fontSize: "1.25rem" }}
      >
        <AngleRightIcon
          sx={{
            marginRight: "0px",
            color: "secondary.main",
            transform: "translateY(3px)",
            fontSize: "1.25rem",
          }}
        />{" "}
        Categoría de documentos de creación
      </Typography>
      <Divider />
      {customLoader ? (
        <Typography>Cargando...</Typography>
      ) : (
        <Grid
          container
          spacing={4}
          maxWidth="lg"
          sx={{
            pt: 2,
            pb: 8,
            "& .MuiGrid-item:last-child:after": { height: "0" },
          }}
        >
          {documentByClientByRole &&
            documentByClientByRole.map((dr: any, i: any) => (
              <Grid
                item
                xs={12}
                key={i}
                sx={{
                  "&:after": {
                    content: '""',
                    width: "calc(100% - 165px)",
                    height: "1px",
                    display: "block",
                    background: "rgb(33, 37, 41)",
                    marginTop: "15px",
                    opacity: "0.25",
                  },
                }}
              >
                <InputLabel sx={{ color: theme.palette.greyDue.dark }}>
                  Categoría {dr.name}{" "}
                  {
                    /* dr.required */
                    ((client.personType === "Física" && dr.requiredPhysical) ||
                      (client.personType === "Jurídica" &&
                        dr.requiredJuridic)) && (
                      <Typography
                        variant="button"
                        sx={{ color: theme.palette.success.main }}
                      >
                        *
                      </Typography>
                    )
                  }
                </InputLabel>
                {dr?.uploaded?.length > 0 ? (
                  <>
                    {dr.uploaded.map((dup: any, i: any) => (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        idClient={id}
                        idDocumentByClient={dup.id}
                        idDocumento={dup.document.id}
                        idStage={dup.stageId}
                        nameDocument={dup.originalName}
                        systemNameDocument={
                          dup.systemName ? dup.systemName : ""
                        }
                        extension={dup.extension}
                        handleUpload={handleAdd}
                        handleDelete={handleDelete}
                        handleOpenModal={handleOpenModal}
                        //require={dr.required}
                        require={
                          (client.personType === "Física" &&
                            dr.requiredPhysical) ||
                          (client.personType === "Jurídica" &&
                            dr.requiredJuridic)
                        }
                        client={client}
                        disabledByStage={disabledByStage}
                        maxSize={dr.maxsizes}
                      />
                    ))}
                    {dr.newField && (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        idClient={id}
                        idDocumentByClient={""}
                        idDocumento={dr.id}
                        idStage={client?.stage.id}
                        handleUpload={handleAdd}
                        //require={dr.required}
                        require={
                          (client.personType === "Física" &&
                            dr.requiredPhysical) ||
                          (client.personType === "Jurídica" &&
                            dr.requiredJuridic)
                        }
                        extensionList={dr.extension}
                        disabledByStage={disabledByStage}
                        maxSize={dr.maxsizes}
                      />
                    )}
                    <Stack alignItems="flex-end">
                      <IconButton
                        sx={{
                          color: "#198754",
                        }}
                        aria-label="add"
                        component="label"
                        data-name={dr.name}
                        onClick={addField}
                        disabled={
                          dr.newField ||
                          (client?.stage.id === 3 &&
                            authInfo.userRole.id === 9) ||
                          disabledByStage
                        }
                      >
                        <CirclePlusIcon sx={{ fontSize: "1.563rem" }} />
                      </IconButton>
                    </Stack>
                  </>
                ) : (
                  <>
                    <FancyInputFile
                      key={i}
                      handleOptions={undefined}
                      label=" "
                      idClient={id}
                      idDocumentByClient={""}
                      idDocumento={dr.id}
                      idStage={client?.stage.id}
                      handleUpload={handleAdd}
                      //require={dr.required}
                      require={
                        (client.personType === "Física" &&
                          dr.requiredPhysical) ||
                        (client.personType === "Jurídica" && dr.requiredJuridic)
                      }
                      requiredDocuments={requiredDocuments}
                      extensionList={dr.extension}
                      disabledByStage={disabledByStage}
                      maxSize={dr.maxsizes}
                    />
                  </>
                )}
              </Grid>
            ))}
          {modalData?.modalType === "delete" && (
            <Dialog
              open={modalData.modalOpen}
              onClose={handleCancelModal}
              fullWidth
            >
              <DeleteModal
                //@ts-ignore
                textChildren={modalData?.modalObject.document}
                actionButton={
                  <>
                    {" "}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 2,
                        mr: 2,
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        mt: 2,
                      }}
                      onClick={handleCancelModal}
                      autoFocus
                    >
                      Cancelar
                    </Button>{" "}
                  </>
                }
              />
            </Dialog>
          )}
          {modalData?.modalType === "view" && (
            <Dialog
              open={modalData.modalOpen}
              onClose={handleCancelModal}
              maxWidth="xl"
              fullWidth
            >
              <FileViewModal
                //@ts-ignore
                document={modalData?.modalObject}
                cancelModal={handleCancelModal}
              />
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
};

export default CreationStage;

import { URL } from "../utils/UrlConstant";
import { privateFetch } from "../utils/CustomFetch.js";
import { properties } from "../utils/Properties_es";
import { CONSTANT } from "../utils/Constant";
import { formatDate } from "../utils/UtilsFunctions";
import Url from "../urls/Url";

export const getRequest = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["code", filter.code],
    ["name", filter.name],
    ["identification", filter.identification],
    ["personType", filter.personType ? filter.personType : ""],
    ["stage", filter.stage ? filter.stage : ""],
    ["user", filter.user ? filter.user : ""],
    ["clientType", filter.clientType ?? ""],
  ]);

  let keysParams = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });

  keysParams.forEach((key) => {
    params.delete(key);
  });

  await privateFetch
    .get(
      URL.REQUEST_CLIENT_LIST(
        formatDate(filter.endDate),
        page,
        Url.registration_x_page,
        formatDate(filter.startDate)
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_management_find_error);
      //throw new Error(error);
      console.log(error);
    });

  return responseData;
};

export const findByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CLIENT(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_findid_error);
    });
  return responseData;
};

export const createRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    code: data.code ? data.code : 0,
    id: 0,
    creatorUser: data.creatorUser,
    email: data.email,
    executive: data.executive,
    identification: data.identification,
    modifierUser: "",
    name: data.name,
    personType: data.personType,
    office: data.office,
    risk: data.risk,
    typeAccount: data.typeAccount,
    fileForm: data.fileForm,
  };
  await privateFetch
    .post(URL.CREATE_CLIENT(null), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_client_save_error);
    });
  return responseData;
};

export const updateRequest = async (data) => {
  console.log(data);
  let responseData = {};
  const requestBody = {
    id: data.id,
    code: data.code ? data.code : 0,
    creatorUser: data.creatorUser,
    email: data.email,
    executive: data.executive,
    identification: data.identification,
    modifierUser: data.modifierUser,
    name: data.name,
    personType: data.personType,
    office: data.office,
    risk: data.risk,
    typeAccount: data.typeAccount,
    fileForm: data.fileForm,
  };
  await privateFetch
    .put(URL.CREATE_CLIENT(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_client_update_error);
    });
  return responseData;
};

export const deleteRequest = async (data) => {
  const params = new URLSearchParams([["user", data.user]]);
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_CLIENT(data.id), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const getRequestDocumentRole = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_DOCUMENT_ROLE)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por perfil");
    });
  return responseData;
};

export const getRequestDocumentClient = async (
  id,
  personType,
  riskType,
  role,
  signatureType
) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["customer", id],
    ["personType", personType],
    [riskType !== null && "riskType", riskType],
    ["role", role],
    ["signatureType", signatureType],
  ]);
  await privateFetch
    .get(URL.GET_CLIENT_DOC, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
  return responseData;
};

export const getRequestDocumentClientTreasury = async (
  id,
  personType,
  riskType,
  role
) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["customer", id],
    ["personType", personType],
    [riskType !== null && "riskType", riskType],
    ["role", role],
    ["treasury", true],
  ]);
  await privateFetch
    .get(URL.GET_CLIENT_DOC, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
  return responseData;
};

export const getRequestDownloadDocumentClient = async (id, nameDocument) => {
  await privateFetch
    .get(URL.DOWNLOAD_DOC(id), { responseType: "blob" })
    .then(async (response) => {
      let responseData = await response.data;
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameDocument);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
};

export const getRequestImageDocumentClient = async (id, nameDocument) => {
  let responseData = {};
  let url;
  await privateFetch
    .get(URL.DOWNLOAD_DOC(id), { responseType: "blob" })
    .then(async (response) => {
      responseData = await response.data;
      url = window.URL.createObjectURL(new Blob([responseData]));
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
  return url;
};

export const getRequestBranch = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["description", filter],
    ["type", "J"],
  ]);
  let keysParams = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key) => {
    params.delete(key);
  });
  await privateFetch
    .get(URL.REQUEST_BRANCH(page, filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const stateNotificationRequest = async (data) => {
  let responseData = {};
  console.log(data);
  const params = new URLSearchParams([
    ["clientId", data.clientId],
    ["state", data.state],
    ["comment", data.comment ?? ""],
  ]);

  let keysParams = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });

  keysParams.forEach((key) => {
    params.delete(key);
  });
  await privateFetch
    .post(URL.STATE_NOTIFICATION(data.clientId, data.state, data.comment), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error();
    });
  return responseData;
};

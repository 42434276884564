import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { AppProvider } from "../../contexts/AppContext";
import { StepProvider } from "../../contexts/StepContext";
import { NotesProvider } from "../../contexts/NotesContext";
import { properties } from "../../utils/Properties_es";
import Loading from "../utils/Loading";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { authPromise }: any = useLoaderData();

  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={authPromise}
        children={(authObject) => (
          <AppProvider authData={authObject}>
            <StepProvider>
              <NotesProvider>{outlet}</NotesProvider>
            </StepProvider>
          </AppProvider>
        )}
      />
    </Suspense>
  );
};

import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useApp } from "../../hooks/useApp";
import { useNote } from "../../hooks/useNote";
import { formatDate } from "../../utils/UtilsFunctions";
import { TrashIcon, EyeIcon } from "../svgicons/SvgIcons";
import AddObservation from "./AddObservation";
import DeleteModal from "./DeleteModal";
import FileViewModal from "./FileViewModal";
import FancyTablePagination from "./FancyTablePagination";
import ResourceAccess from "../security/ResourceAccess";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme/theme";

interface DialogProps {
  id: string;
  closeModal?: any;
}

interface addObservation {
  description: string;
  file?: FormData;
}

const Observations = ({ id, closeModal }: DialogProps) => {
  const { authInfo, modalData, setModalData, errorMsg } = useApp();
  const {
    notesData,
    handleFetchNotes,
    handleAddNotes,
    handleChangePage,
    handleDeleteNote,
    page,
    setIdClient,
  } = useNote();

  const defaultValues: addObservation = {
    description: "",
  };

  const { handleSubmit } = useForm<addObservation>({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const dataInit = async () => {
      handleFetchNotes && handleFetchNotes(id, 0);
      setIdClient && setIdClient(id);
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    let object = null;
    const modalAction = event.currentTarget.getAttribute("data-name");

    const id = event.currentTarget.getAttribute("data-id");
    const nameD = event.currentTarget.getAttribute("data-name-document");
    const extensionD = event.currentTarget.getAttribute("data-extension");

    if (modalAction === "deleteNote") {
      object = event.target.attributes["data-id"].value;
    }
    if (modalAction === "viewNote") {
      object = { id: id, nameD: nameD, ext: extensionD, service: "client" };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "addNote":
        handleAddNotes && handleAddNotes(data, id, "");
        break;
      case "deleteNote":
        //@ts-ignore
        handleDeleteNote(modalData?.modalObject);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <DialogTitle>Histórico de observaciones</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 14,
          top: 14,
          color: theme.palette.info.dark,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 4, md: 8 }, pb: 0 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={{ mt: 4, mb: 6 }}
        >
          <Button
            variant="contained"
            color="primary"
            data-name="addNote"
            onClick={handleOpenModal}
          >
            Agregar
          </Button>
        </Stack>
        <Divider />
        <Grid container spacing={2} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Autor</TableCell>
                <TableCell>Fecha de creación</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notesData !== null &&
                notesData.content &&
                notesData.content.map((r: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {r.id}
                    </TableCell>
                    <TableCell>{r.description}</TableCell>
                    <TableCell>{r.createdBy}</TableCell>
                    <TableCell>{formatDate(new Date(r.createdAt))}</TableCell>
                    <TableCell>
                      <Tooltip title="Ver imagen" placement="top">
                        <IconButton
                          sx={{
                            color: "#ffc107",
                          }}
                          aria-label="viewNote"
                          component="label"
                          onClick={handleOpenModal}
                          data-name="viewNote"
                          data-id={r.id}
                          data-name-document={r.systemName}
                          data-extension="png"
                          disabled={r.systemName === null}
                        >
                          <EyeIcon sx={{ fontSize: "1.75rem" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Eliminar" placement="top">
                        <IconButton
                          color="success"
                          aria-label="trash"
                          component="label"
                          onClick={handleOpenModal}
                          data-name="deleteNote"
                          data-id={r.id}
                          disabled={
                            authInfo?.userRole?.id === 15 &&
                            ResourceAccess(true, "CHANGESTAGE:WRITE") === false
                          }
                        >
                          <TrashIcon sx={{ pointerEvents: "none" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {(!notesData || notesData?.content?.length <= 0) && (
                <TableRow>
                  <TableCell colSpan={5}>
                    No existen observaciones para este cliente
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <FancyTablePagination
            count={
              notesData?.content?.length > 0 ? notesData?.content?.length : 0
            }
            rowsPerPage={notesData?.size}
            page={page ? page : 0}
            onPageChange={handleChangePage}
            totalElements={notesData?.totalElements}
            totalPages={notesData?.totalPages}
          />
        </Grid>
      </DialogContent>
      {modalData?.modalType === "addNote" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddObservation
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            idClient={id}
          />
        </Dialog>
      )}
      {modalData?.modalType === "deleteNote" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={`observación ${modalData?.modalObject}`}
            actionButton={
              <>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
      {modalData?.modalType === "viewNote" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          maxWidth="xl"
          fullWidth
        >
          <FileViewModal
            //@ts-ignore
            document={modalData?.modalObject}
            cancelModal={handleCancelModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default Observations;

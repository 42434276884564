import { useEffect, useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { updateStateClient } from "../../types/types";
import { useForm, Controller } from "react-hook-form";

const InputLabelForm = styled(InputLabel)(() => ({
  transform: "translate(0px, -9px) scale(0.75)",
  color: "#5d5e60",
}));

interface DialogProps {
  form: any;
  onSubmit: any;
  cancelModal: any;
  stage: any;
}

const AddOrUpdateClientModal = ({
  onSubmit,
  cancelModal,
  form,
  stage,
}: DialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<updateStateClient>({
    defaultValues: form,
    mode: "onChange",
  });
  const [statusDat, setStatusDat] = useState<any>([]);
  let statusData: any = [];

  useEffect(() => {
    stage.some(
      (s: any) =>
        s?.id === form?.stage?.id &&
        (statusData.push(s.statusApprove), statusData.push(s.statusReject))
    );
    setStatusDat(statusData);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DialogTitle>Cambiar estado</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {statusDat?.length > 0 && (
              <Grid item xs={12}>
                <Controller
                  name={"status"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabelForm
                        error={errors.status && Boolean(errors.status)}
                      >
                        Estado
                      </InputLabelForm>
                      <Select
                        variant="standard"
                        value={value}
                        {...register("status")}
                        error={errors.status && Boolean(errors.status)}
                      >
                        <MenuItem value={statusDat[0]?.id}>
                          {statusDat[0]?.name}
                        </MenuItem>
                        <MenuItem value={statusDat[1]?.id}>
                          {statusDat[1]?.name}
                        </MenuItem>
                      </Select>
                      {errors.status && (
                        <FormHelperText>{errors.status.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateClientModal;

import { useEffect, useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  stateNotificationRequest,
  updateRequest,
} from "../../services/ManagementClientsService";
import { useApp } from "../../hooks/useApp";

interface notificationType {
  clientId: number;
  comment?: string;
  state?: string;
}

interface DialogProps {
  client: notificationType;
  cancelModal: any;
  handleFetchData: any;
  defaultValuesFilter: any;
}

const DocumentNotification = ({
  client,
  cancelModal,
  handleFetchData,
  defaultValuesFilter,
}: any) => {
  const { authInfo, setLoading, setErrorMsg, setSuccessMsg } = useApp();
  const defaultValues: notificationType = {
    clientId: client?.id ?? 0,
    comment: "",
    state: "",
  };

  const notificationSchema = {
    clientId: Yup.number().required("Campo es requerido"),
    comment: Yup.string(),
    state: Yup.string(),
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<notificationType>({
    defaultValues,
    //@ts-ignore
    //resolver: yupResolver(notificationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [client]);

  const handleUpdateClientData = async (
    data: any,
    notificationState: number
  ) => {
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        notificationState: notificationState,
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        return;
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleNotification = async (data: notificationType, action: string) => {
    try {
      setLoading && setLoading(true);
      let formatState = 4;
      let notificationState = 1;
      action === "cumplimiento"
        ? (formatState = 1)
        : action === "rechazado"
        ? (formatState = 2)
        : action === "no recibido"
        ? (formatState = 3)
        : (formatState = 4);

      const request = await stateNotificationRequest({
        ...data,
        state: formatState,
      });
      const update = await handleUpdateClientData(
        client,
        formatState === 2 || formatState === 3 ? 1 : formatState === 1 ? 2 : 3
      );
      if (request) {
        setLoading && setLoading(false);
        setSuccessMsg && setSuccessMsg("Notificación enviada");
        handleFetchData(0, defaultValuesFilter);
        cancelModal();
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    console.log(data);
    console.log(e);
    const stateAction = e.target.getAttribute("data-name");
    console.log("stateAction", stateAction);
    await handleNotification(data, stateAction);
  };

  return (
    <>
      <DialogTitle>Notificación documentos físicos</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              {authInfo?.userRole?.id === 10 ? (
                <Controller
                  name={"comment"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Observación"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("comment")}
                      error={errors.comment && Boolean(errors.comment)}
                      helperText={errors.comment && errors.comment.message}
                    />
                  )}
                />
              ) : (
                <Typography variant="body1" color="initial">
                  Enviar notificación a departamento de cumplimiento
                </Typography>
              )}
            </Grid>
          </Grid>
          {/*  <Controller
            name={"clientId"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                hidden
                value={value}
                {...register("clientId", { required: true })}
              />
            )}
          />
          <Controller
            name={"clientId"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                hidden
                value={value}
                {...register("clientId", { required: true })}
              />
            )}
          /> */}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
          data-name={
            authInfo?.userRole?.id === 10 ? "recibido" : "cumplimiento"
          }
        >
          {authInfo?.userRole?.id === 10 ? "Recibido" : "Enviar"}
        </Button>
        {authInfo?.userRole?.id === 10 && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleSubmit(onSubmit)}
            data-name="rechazado"
          >
            Rechazar
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default DocumentNotification;

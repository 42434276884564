import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Paper,
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  FormControl,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  styled,
  alpha,
  FormHelperText,
} from "@mui/material";
import TabsCustomerHome from "../components/TabsCustomerHome";
import {
  AngleRightIcon,
  CirclePlusIcon,
  TrashIcon,
} from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createClientsTypes, user } from "../types/types";
import { clientSchema, validate } from "../validations/Validations";
import { uploadBulkRequest } from "../services/documents/DocumentsService";
import {
  createRequest,
  findByIdRequest,
  updateRequest,
  getRequestDocumentClient,
} from "../services/ManagementClientsService";
import { changeState } from "../services/StageAndFlowService";
import { properties } from "../utils/Properties_es";
import { data } from "../resources/data/data";
import ExecutiveStage from "./stage/ExecutiveStage";
import FormCreateClient from "../components/FormCreateClient";
import { PatternFormat } from "react-number-format";

const DropZone = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: alpha(theme.palette.secondary.dark, 0.3),
  borderStyle: "dashed",
  backgroundColor: "#FFFFFF",
  color: theme.palette.text.primary,
  outline: "none",
  transition: "border .24s ease-in-out",
}));

interface Category {
  id: number;
  name: string;
  required: boolean;
  extension: any;
}

interface FileCategory {
  idDocumento: number;
  filename: string;
}

interface UploadResult {
  categories: FileCategory[];
  files: FormData;
}

interface Props {
  categories: Category[];
}

interface requestUpload {
  error: boolean;
  message: string;
  responseCode: string;
}

//const BulkUploadCustomerDocs: React.FC<Props> = ({ categories }) => {
const BulkUploadCustomerDocs = () => {
  const { client, setClient, handleFetchClient, dataBranch, usersData } =
    useStep();
  //const [client, setClient] = useState<any>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);
  const [riskMore, setriskMore] = useState(null);
  const [documentByClient, setDocumentByClient] = useState<any | null>(null);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [filterExecutive, setFilterExecutive] = useState<user[] | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    setDetailsMsg,
  } = useApp();

  useLayoutEffect(() => {
    const dataInit = async () => {
      if (id) {
        handleFetchClient && handleFetchClient(id);
      } else {
        setClient && setClient(null);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  console.log("documentByClient: ", documentByClient);

  const defaultValues: createClientsTypes = {
    id: client?.id ? client.id : "",
    code: client?.code ? client.code : "",
    creationtDate: new Date(),
    creatorUser: client?.creatorUser ? client.creatorUser : authInfo?.username,
    executive: client?.executive ? client.executive : authInfo?.username,
    modificationDate: new Date(),
    modifierUser: client?.modifierUser
      ? client.modifierUser
      : authInfo?.username,
    personType: client?.personType ? client.personType : "Física",

    stage: client?.stage ? client.stage : "Identificación",
    office: client?.office ? client.office : "",
    typeAccount: client?.typeAccount ? client.typeAccount : "",
    fileForm: client?.fileForm ? client.fileForm : "",
    clientStatus: client?.clientStatus ? client.clientStatus : "6",
    accounts: (client?.accounts && client.accounts) ?? [
      {
        client: 0,
        name: "",
        typeIdentification: "",
        identification: "",
        email: "",
        risk: "",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    control,
    watch,
    reset,
    setError,
    resetField,
    setValue,
  } = useForm<createClientsTypes>({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const watch_personType: any = watch("personType");
  const watch_riskType: any = watch("accounts");
  const watch_typeAccount: any = watch("typeAccount");
  const watch_accounts: any = watch("accounts");
  const watch_signatureType: any = watch("fileForm");
  const watch_office: any = watch("office");
  const {
    fields: addAccountsField,
    append: addAccountsAppend,
    remove: addAccountsRemove,
  } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 2,
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [client]);

  useEffect(() => {
    if (data.code === "") {
      setError("code", { message: "Código es requerido" });
      return;
    }
  }, [isSubmitted]);

  const uploadButton = document.getElementById(
    "upload-button"
  ) as HTMLInputElement;

  useEffect(() => {
    const loadData = async () => {
      await loadDataDocuments(watch_personType, watch_riskType);
      return;
    };
    loadData();
  }, [watch_personType, watch_riskType]);

  useEffect(() => {
    if (watch_personType === "Física" && isDirty) {
      resetField("typeAccount", {
        defaultValue: "",
      });
    }
    if (watch_personType === "Jurídica") {
      setValue("typeAccount", "Jurídicas");
    }
  }, [watch_personType]);

  const loadDataDocuments = async (
    watch_personType: any,
    watch_riskType: any
  ) => {
    let riskMoreTall: any = null;
    watch_riskType.filter((c: any) => {
      if (c.risk.toLowerCase() === data.risk[3].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[2].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[1].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else {
        riskMoreTall = c;
        return true;
      }
    });
    //@ts-ignore
    riskMoreTall !== null &&
      riskMoreTall?.id > 0 &&
      setriskMore(riskMoreTall.risk);

    const dataDocuments = async () => {
      if (id && riskMoreTall !== null && riskMoreTall?.id > 0) {
        const loadDocuments = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          9,
          watch_signatureType
        );

        const loadDocumentsCompliance = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          10,
          watch_signatureType
        );
        const loadDocumentsOperations = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          11,
          watch_signatureType
        );

        const loadDocumentsAnalysis = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          24,
          watch_signatureType
        );
        let documents = [
          ...loadDocuments,
          ...loadDocumentsCompliance,
          ...loadDocumentsOperations,
          ...loadDocumentsAnalysis,
        ];

        let documentsFilter: any[] = [];

        const unique = documents.filter((item: any) => {
          const isDuplicate = documentsFilter.includes(item.id);

          if (!isDuplicate) {
            documentsFilter.push(item.id);
            return true;
          }
        });

        setDocumentByClient(unique);
      }
    };
    dataDocuments();
  };

  useEffect(() => {
    if (watch_office.id !== 0) {
      const filterUser: user[] | undefined = usersData?.filter(
        (item: user) => Number(item.userBranchOffice) === watch_office.id
      );
      if (filterUser) {
        if (!client?.executive || client?.office.id !== watch_office.id) {
          setValue("executive", {
            id: 0,
            username: "",
            firstName: "",
            lastName: "",
            phone: 0,
            status: "",
            userRole: "",
            activationCode: "",
            userBranchOffice: "",
          });
        }
        setFilterExecutive(filterUser);
      }
    }
  }, [watch_office]);

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role,
        signatureType
      );
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de agregar registro   *
   * @param data
   */
  const handleAddClient = async (data: any) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData: any = await createRequest({
        ...data,
        creatorUser: authInfo?.username,
        stage: { id: 8 },
        clientStatus: data.clientStatus,
        clientType: "DueDiligence",
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);

      if (addData?.blackListVerification.some((bl: any) => bl.inBlackList)) {
        let names: string[] = [];
        await addData?.blackListVerification.map(
          (bl: any) => bl.inBlackList && names.push(bl.name)
        );

        setDetailsMsg &&
          setDetailsMsg(
            `Hemos detectado que ${
              names?.length > 1 ? "los clientes" : "el cliente"
            } <br/><strong style="color: #d32f2f">${addData?.blackListVerification
              .map((bl: any, i: number) =>
                bl.inBlackList === true &&
                i !== addData.blackListVerification.length - 1
                  ? bl.name.replace(/[^\w\s]/gi, "") + `<br>`
                  : bl.name.toString().replaceAll(",", "")
              )
              .flat()
              .join("")}</strong> <br/>se ${
              names?.length > 1 ? "encuentran" : "encuentra"
            } en la lista negra. <br/>Por favor comuníquese con cumplimiento`
          );
      }

      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      navigate(`/bulk-upload-customer-docs/${addData.clientDTO.id}`);
      handleFetchClient && handleFetchClient(addData.clientDTO.id);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdateClientData = async (data: any) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        id: id,
        modifierUser: authInfo?.username,
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangeState = async (data: any) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        id: id,
        stage: { id: 8 },
        modifierUser: authInfo?.username,
      });
      let changeStateData = await changeState({
        clientId: Number(id),
        statusId: 1,
        user: authInfo?.username,
      });

      if (!addData || !changeStateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      navigate("/");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleCloseModalFiles = () => {
    setModalOpen(false);
    setUploadResult(null);
    setFiles([]);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFileCategories: FileCategory[] = [];
      const newFiles: File[] = [];

      acceptedFiles?.forEach((file) => {
        const matchingCategory = documentByClient?.find(
          (category: any) =>
            file.name
              .toLowerCase()
              .replace(/\s_|_|#|-|@|<>/g, "")
              .indexOf(category.name.toLowerCase()) !== -1
        );

        if (matchingCategory) {
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          if (
            fileExtension &&
            JSON.parse(matchingCategory.extension).includes(`.${fileExtension}`)
          ) {
            const fileAlreadyAdded = uploadResult?.files.get(file.name);
            if (!fileAlreadyAdded) {
              newFileCategories.push({
                idDocumento: matchingCategory.id,
                filename: file.name,
              });
              newFiles.push(file);
            }
          }
        } else {
          const fileAlreadyAdded = uploadResult?.files.get(file.name);
          if (!fileAlreadyAdded) {
            newFileCategories.push({
              idDocumento: -1, // Use -1 to indicate "Other" category
              filename: file.name,
            });
            newFiles.push(file);
            if (uploadButton) {
              uploadButton.disabled = true;
            }
          }
        }
      });

      if (newFiles.length > 0) {
        setUploadResult((prevState: UploadResult | null) => ({
          ...prevState!,
          files: (() => {
            const formData = new FormData();
            newFiles.forEach((file) => {
              formData.append("files", file);
            });
            return formData;
          })(),
          categories: [...(prevState?.categories ?? []), ...newFileCategories],
        }));
        setFiles(acceptedFiles);
        setModalOpen(true);
        //setSelectedFile(newFiles[0]);
      }
    },
    [documentByClient, uploadButton, uploadResult]
  );
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const categoryId = event.target.value;
    if (uploadResult) {
      const updatedCategories = [...uploadResult.categories];
      const categoryIndex = updatedCategories.findIndex(
        (category) => category.filename === files[index].name
      );
      if (categoryIndex !== -1) {
        updatedCategories[categoryIndex] = {
          ...updatedCategories[categoryIndex],
          idDocumento: Number(categoryId),
        };
      } else {
        updatedCategories.push({
          idDocumento: Number(categoryId),
          filename: files[index].name,
        });
      }
      setUploadResult({
        ...uploadResult,
        categories: updatedCategories,
      });
    }
  };

  const handleFileDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (uploadResult) {
      const updatedCategories = uploadResult.categories.filter(
        (category) => category.filename !== files[index].name
      );

      const updatedFiles = new FormData();
      updatedCategories.forEach((category) => {
        const file = newFiles.find((f) => f.name === category.filename);
        if (file) {
          updatedFiles.append("files", file);
        }
      });
      setUploadResult({
        ...uploadResult,
        files: updatedFiles,
        categories: updatedCategories,
      });
    }
  };

  const handleUpload = async () => {
    setLoading && setLoading(true);
    if (uploadResult) {
      console.log(uploadResult);
      const formData = new FormData();
      // Agregar todos los archivos al array uploadResult.files
      const filesArray: any = [];
      uploadResult.files.forEach((file: any) => {
        filesArray.push(file);
      });
      // Convertir el array de archivos en un objeto Blob
      const filesBlob = new Blob(filesArray);

      uploadResult.categories.forEach((category, index) => {
        formData.append(`categories[${index}].filename`, category.filename);
        formData.append(
          `categories[${index}].idDocumento`,
          `${category.idDocumento}`
        );
      });
      id && formData.append("idClient", id);
      formData.append("idStage", "1");
      formData.append("user", authInfo?.username);

      uploadResult.files.forEach((file: any) => {
        formData.append("files", file);
      });

      try {
        let request: any = await uploadBulkRequest(formData);
        if (request?.error) {
          setErrorMsg && setErrorMsg(request.message);
          setModalOpen(false);
          await loadDataDocuments(watch_personType, watch_riskType);
          setLoading && setLoading(false);
          return;
        }
        setLoading && setLoading(false);
        setModalOpen(false);
        setUploadResult(null);
        await loadDataDocuments(watch_personType, watch_riskType);
        setSuccessMsg && setSuccessMsg(request.message);
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
      }
    } else {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg("Debe seleccionar una categoría");
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();

    const typeAction = e.target.name;
    switch (typeAction) {
      case "create":
        await handleAddClient(data);
        break;
      case "update":
        await handleUpdateClientData(data);
        break;
      case "updatestage":
        await handleChangeState(data);
        break;
      default:
        break;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const typeIdentification = ["Cédula", "Pasaporte"];

  return (
    <>
      <Box
        sx={{ px: 3.75, pt: 12, pb: 12, mr: "auto", ml: "auto" }}
        maxWidth="xl"
      >
        <TabsCustomerHome />
        <Paper
          sx={{ padding: theme.spacing(10), borderRadius: theme.spacing(4) }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
              Crear cliente
              <br />
              <small style={{ fontSize: "1rem" }}>
                <b>Creado por:</b> {client?.creatorUser}
              </small>
            </Typography>
          </Stack>
          <Grid container spacing={6} maxWidth="lg" alignItems="flex-end">
            <Grid item md={6} sm={6} xs={12}>
              <Typography
                variant="h5"
                color="greyDue.dark"
                sx={{ fontWeight: 400, fontSize: "1.25rem" }}
              >
                <AngleRightIcon
                  sx={{
                    marginRight: "0px",
                    color: "secondary.main",
                    transform: "translateY(3px)",
                    fontSize: "1.25rem",
                  }}
                />{" "}
                Datos del cliente
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Controller
                control={control}
                name="office"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={
                      dataBranch ?? [
                        {
                          address: "",
                          id: 0,
                          name: "",
                          notaryId: 0,
                          tableName: "",
                          type: "",
                        },
                      ]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sucursal"
                          variant="standard"
                          error={!!errors.office}
                          helperText={errors.office && errors.office.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                name={"clientStatus"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel>Tipo de cliente</InputLabel>
                    <Select
                      value={value}
                      {...register("clientStatus")}
                      disabled={false}
                    >
                      <MenuItem value="">Seleccione</MenuItem>
                      <MenuItem value="1">Cliente nuevo</MenuItem>
                      <MenuItem value="7">Cliente actualización</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ pt: 4 }} />
          <Grid container spacing={6} maxWidth="lg">
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                name={"code"}
                control={control}
                rules={{ validate }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Código del cliente"
                    type="text"
                    variant="standard"
                    fullWidth
                    value={value}
                    {...register("code")}
                    error={errors.code && Boolean(errors.code)}
                    helperText={errors.code && errors.code.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Controller
                control={control}
                name="executive"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={
                      filterExecutive ?? [
                        {
                          id: 0,
                          username: "",
                          firstName: "",
                          lastName: "",
                          phone: 0,
                          status: "",
                          userRole: "",
                          activationCode: "",
                          userBranchOffice: "",
                        },
                      ]
                    }
                    getOptionLabel={(option: any) =>
                      filterExecutive && option.firstName
                        ? option.firstName + " " + option.lastName
                        : ""
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value ?? []}
                    sx={{ pb: 0 }}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Ejecutivo"
                          variant="standard"
                          error={!!errors.office}
                          helperText={errors.office && errors.office.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Controller
                control={control}
                name="personType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={data.personType}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de persona"
                          variant="standard"
                          error={!!errors.personType}
                          helperText={
                            errors.personType && errors.personType.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Controller
                control={control}
                name="fileForm"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={data.fileForm}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Modalidad de Firma"
                          variant="standard"
                          error={!!errors.fileForm}
                          helperText={
                            errors.fileForm && errors.fileForm.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <Controller
                control={control}
                name="typeAccount"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={
                      watch_personType === "Física"
                        ? data.typeAccount
                        : data.typeAccountLegal
                    }
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    //@ts-ignore
                    value={value}
                    disabled={watch_personType === "Jurídica"}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de cuenta"
                          variant="standard"
                          error={!!errors.typeAccount}
                          helperText={
                            errors.typeAccount && errors.typeAccount.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {addAccountsField.map((field, index) => {
            return (
              <div key={index}>
                <Divider sx={{ pt: 3, mb: 3 }} />
                <Grid container spacing={6} maxWidth="lg">
                  <Grid item xs={3} md={1}>
                    <Controller
                      name={`accounts.${index}.typeIdentification`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth variant="standard">
                          <Select
                            value={value}
                            {...register(
                              `accounts.${index}.typeIdentification` as const
                            )}
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "4px 0 10px",
                              },

                              marginTop: "16px",
                            }}
                            error={
                              errors?.accounts?.[index]?.typeIdentification &&
                              true
                            }
                          >
                            {watch_personType === "Física" ? (
                              typeIdentification.map((m: any, i: number) => (
                                <MenuItem value={m} key={i}>
                                  {m}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="RNC">RNC</MenuItem>
                            )}
                          </Select>
                          <FormHelperText error={true}>
                            {
                              errors?.accounts?.[index]?.typeIdentification
                                ?.message
                            }
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item md={2} xs={9}>
                    <Controller
                      name={`accounts.${index}.identification`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        watch_accounts[index].typeIdentification !==
                        "Pasaporte" ? (
                          <>
                            <FormControl
                              sx={{ width: "100%", marginTop: "20px" }}
                            >
                              <PatternFormat
                                className="input-due"
                                format={
                                  watch_accounts[index].typeIdentification ===
                                  "Cédula"
                                    ? "###-#######-#"
                                    : "#-##-#####-#"
                                }
                                value={value}
                                onChange={onChange}
                                placeholder={
                                  watch_accounts[index].typeIdentification ===
                                  "Cédula"
                                    ? "123-1234567-1"
                                    : "1-12-12345-1"
                                }
                                valueIsNumericString={true}
                              />
                            </FormControl>
                          </>
                        ) : (
                          <TextField
                            type="text"
                            variant="standard"
                            fullWidth
                            label=""
                            value={value}
                            sx={{
                              marginTop: "14px",
                              "&.Mui-disabled:before": {
                                borderBottomStyle: "dotted!important",
                              },
                            }}
                            {...register(
                              `accounts.${index}.identification` as const
                            )}
                            error={
                              errors?.accounts?.[index]?.identification && true
                            }
                            /* helperText={
                            errors?.accounts?.[index]?.identification?.message
                          } */
                          />
                        )
                      }
                    />
                    <FormHelperText>
                      {errors?.accounts?.[index]?.identification?.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`accounts.${index}.name`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Nombres y Apellidos"
                          type="text"
                          variant="standard"
                          fullWidth
                          value={value}
                          {...register(`accounts.${index}.name` as const)}
                          error={errors?.accounts?.[index]?.name && true}
                          helperText={errors?.accounts?.[index]?.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`accounts.${index}.email`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Email"
                          type="mail"
                          variant="standard"
                          fullWidth
                          value={value}
                          {...register(`accounts.${index}.email` as const)}
                          error={errors?.accounts?.[index]?.email && true}
                          helperText={errors?.accounts?.[index]?.email?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12} sx={{ display: "flex" }}>
                    <Controller
                      control={control}
                      name={`accounts.${index}.risk`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          fullWidth
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={data.risk}
                          getOptionLabel={(option: any) => option || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option === value
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Riesgo"
                                variant="standard"
                                error={errors?.accounts?.[index]?.risk && true}
                                helperText={
                                  errors?.accounts?.[index]?.risk?.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                    {index > 0 && (
                      <FancyTooltip title="Remover cuenta" placement="top">
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            addAccountsRemove(index);
                          }}
                        >
                          <RemoveCircleIcon sx={{ fontSize: "1.563rem" }} />
                        </IconButton>
                      </FancyTooltip>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
          {addAccountsField.length < 4 &&
            watch_typeAccount === "Mancomunada" && (
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <FancyTooltip title="Agregar nueva cuenta" placement="top">
                  <IconButton
                    sx={{
                      color: "#198754",
                    }}
                    aria-label="add"
                    component="label"
                    onClick={() => {
                      addAccountsAppend({
                        client: 0,
                        name: "",
                        identification: "",
                        email: "",
                        risk: "",
                      });
                    }}
                  >
                    <CirclePlusIcon sx={{ fontSize: "1.563rem" }} />
                  </IconButton>
                </FancyTooltip>
              </Grid>
            )}
          {errors?.accounts && (
            <FormHelperText sx={{ fontSize: "1rem" }}>
              {errors.accounts.message}
            </FormHelperText>
          )}
          {id && documentByClient !== null && (
            <>
              <Grid container spacing={6} maxWidth="lg" sx={{ my: 5 }}>
                <Grid item xs={12}>
                  <DropZone {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Suelta los archivos aquí...</p>
                    ) : (
                      <p>
                        Arrastre y suelte algunos archivos aquí, o haga clic
                        para seleccionar archivos
                      </p>
                    )}
                  </DropZone>
                </Grid>
              </Grid>
              <ExecutiveStage
                personTypeData={watch_personType}
                riskTypeData={riskMore}
                documentByClient={documentByClient}
                handleFetchDocumentsRole={handleFetchDocumentsRole}
                handleFetchClient={handleFetchClient}
                client={client}
                requiredDocuments={false}
                disabledByStage={true}
              />
            </>
          )}

          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 4 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/")}
            >
              Regresar
            </Button>
            {(!id || isDirty) && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mr: 2,
                }}
                data-name="create"
                name="create"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Guardar
              </Button>
            )}
            {!isDirty && (
              <Button
                variant="contained"
                data-name="updatestage"
                name="updatestage"
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Guardar y Enviar
              </Button>
            )}
          </Stack>
        </Paper>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModalFiles}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>Carga masiva de documentos</DialogTitle>
        <DialogContent sx={{ mt: 5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Documento</TableCell>
                <TableCell>Categoría</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => {
                const matchingCategory = uploadResult?.categories.find(
                  (category) => category.filename === file.name
                );
                return (
                  <TableRow key={index}>
                    <TableCell>{file.name}</TableCell>
                    <TableCell sx={{ padding: "15px 12px" }}>
                      <FormControl fullWidth>
                        {matchingCategory &&
                        matchingCategory.idDocumento !== -1 ? (
                          <Select
                            value={matchingCategory.idDocumento}
                            onChange={(event: any) =>
                              handleCategoryChange(event, index)
                            }
                          >
                            {documentByClient.map((category: any) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            onChange={(event: any) =>
                              handleCategoryChange(event, index)
                            }
                            displayEmpty
                            value=""
                            renderValue={(selected: any) => {
                              if (selected?.length === 0) {
                                return <>Seleccione una categoría</>;
                              }

                              return selected.join(", ");
                            }}
                          >
                            <MenuItem disabled value="">
                              Seleccione una categoría
                            </MenuItem>
                            {documentByClient.map(
                              (category: any, index: number) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FancyTooltip title="Eliminar" placement="top">
                        <IconButton
                          color="success"
                          aria-label="trash"
                          component="label"
                          onClick={() => handleFileDelete(index)}
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {id && (
            <DialogActions
              sx={{ pb: 4, px: 4, borderTop: "1px solid #dee2e6" }}
            >
              <Button
                variant="contained"
                color="primary"
                id="upload-button"
                disabled={uploadButtonDisabled}
                onClick={handleUpload}
              >
                Subir documentos
              </Button>
              <Button
                variant="contained"
                color="secondary"
                id="upload-button"
                disabled={uploadButtonDisabled}
                onClick={handleCloseModalFiles}
              >
                Cancelar
              </Button>
            </DialogActions>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default BulkUploadCustomerDocs;

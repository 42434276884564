import { useState, createContext, useLayoutEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import {
  findByIdRequest,
  getRequestBranch,
} from "../services/ManagementClientsService";
import { setItem, removeItem } from "../utils/LocalStorageManager";
import { useApp } from "../hooks/useApp";
import { branch, user } from "../types/types";
import { getRequestUser } from "../services/UsersService";
import {
  createRequest,
  getRequestDocumentClient,
  updateRequest,
  getRequestDocumentClientTreasury,
} from "../services/ManagementClientsService";
import { properties } from "../utils/Properties_es";

interface clientsTypes {
  id: string;
  code: string;
  creationtDate: Date;
  creatorUser: string;
  executive: string;
  modificationDate: Date;
  modifierUser: string;
  personType: string;
  risk: string;
  stage: string;
  office: string;
  typeAccount: string;
  fileForm: string;
  accounts: {
    client: number;
    name: string;
    identification: string;
    email: string;
    risk: string;
  }[];
}

interface StepContextType {
  activeStep: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  handleBack?: () => void;
  handleNext?: () => void;
  handleReturn?: () => void;
  handleAddClient?: (
    data: any,
    personType: string,
    riskType: string,
    role: number,
    signatureType: string
  ) => void;
  handleFetchDocumentsRole?: (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => void;
  handleFetchDocumentsRoleTreasury: (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number
  ) => void;
  handleUpdateClientData?: (data: any, id: string) => void;
  handleFetchClient?: (id: string) => void;
  client?: any;
  setClient?: Dispatch<SetStateAction<any>>;
  updateDataClient?: boolean;
  activeStepTreasury: number;
  setActiveStepTreasury?: Dispatch<SetStateAction<number>>;
  secondActiveStepTreasury: number;
  setSecondActiveStepTreasury?: Dispatch<SetStateAction<number>>;
  showSecondList: boolean;
  setshowSecondList?: Dispatch<SetStateAction<boolean>>;
  handleBackTreasury?: () => void;
  handleNextTreasury?: () => void;
  handleBackTreasurySecond?: () => void;
  handleNextTreasurySecond?: () => void;
  handleReturnTreasury: () => void;
  address: any;
  setAddress?: Dispatch<SetStateAction<any>>;
  dataJ: any;
  setAllDataJ?: Dispatch<SetStateAction<any>>;
  dataBranch: branch[] | null;
  setDataBranch?: Dispatch<SetStateAction<branch[] | null>>;
  usersData: user[] | null;
  treasuryUsersData: user[] | null;
  setUsersData?: Dispatch<SetStateAction<user[] | null>>;
  handleBranch?: () => void;
}

const initialState = {
  activeStep: 0,
  activeStepTreasury: 0,
  secondActiveStepTreasury: 0,
  showSecondList: false,
  address: {
    provinces: {},
    sector: {},
  },
  dataJ: [],
  handleReturnTreasury: () => true,
  dataBranch: null,
  usersData: null,
  treasuryUsersData: null,
  handleFetchDocumentsRoleTreasury: () => true,
};

const StepContext = createContext<StepContextType>(initialState);

const StepProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const { authInfo, setLoading, setErrorMsg, setSuccessMsg, setDetailsMsg } =
    useApp();
  const [activeStep, setActiveStep] = useState(initialState.activeStep);
  const [activeStepTreasury, setActiveStepTreasury] = useState(
    initialState.activeStepTreasury
  );
  const [secondActiveStepTreasury, setSecondActiveStepTreasury] = useState(
    initialState.secondActiveStepTreasury
  );
  const [showSecondList, setshowSecondList] = useState(
    initialState.showSecondList
  );
  const [client, setClient] = useState<clientsTypes | null>(null);
  const [updateDataClient, setUpdateDataClient] = useState(false);
  const [address, setAddress] = useState<any>({
    provinces: {},
    sector: {},
  });
  const [dataJ, setAllDataJ] = useState<any>([]);
  const [dataBranch, setDataBranch] = useState<branch[] | null>(null);
  const [usersData, setUsersData] = useState<any>(null);
  const [treasuryUsersData, setTreasuryUsersData] = useState<any>(null);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  //Steps treasury
  const handleBackTreasury = async () => {
    localStorage.setItem(
      "activeTreasury",
      JSON.stringify(activeStepTreasury - 1)
    );
    setActiveStepTreasury((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextTreasury = async () => {
    localStorage.setItem(
      "activeTreasury",
      JSON.stringify(activeStepTreasury + 1)
    );
    setActiveStepTreasury((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackTreasurySecond = async () => {
    localStorage.setItem(
      "activeSecondTreasury",
      JSON.stringify(secondActiveStepTreasury - 1)
    );
    setSecondActiveStepTreasury((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextTreasurySecond = async () => {
    localStorage.setItem(
      "activeSecondTreasury",
      JSON.stringify(secondActiveStepTreasury + 1)
    );
    setSecondActiveStepTreasury((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReturn = () => {
    setClient(null);
    setActiveStep(0);

    if (authInfo.userRole?.id === 25) {
      navigate("/legalregistry");
    } else {
      navigate("/");
    }
  };

  const handleReturnTreasury = () => {
    setClient(null);
    setActiveStepTreasury(0);
    setSecondActiveStepTreasury(0);
    removeItem("activeTreasury");
    removeItem("activeSecondTreasury");
    navigate("/legalregistry");
    return true;
  };

  const handleFetchClient = async (id: string) => {
    let clientData: any = null;
    setUpdateDataClient(false);
    try {
      clientData = await findByIdRequest(id);
      if (clientData) {
        setClient(clientData.content);
        setUpdateDataClient(true);
      }
      return clientData;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleBranch = async () => {
    try {
      const getBranch: any = await getRequestBranch(0, "");
      if (getBranch) {
        setDataBranch(getBranch.content);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchUser = async () => {
    try {
      let users: any = null;
      users = await getRequestUser(0, "Ejecutivo");
      if (users) {
        let usersFilter = await users.content.filter(
          (user: any) =>
            user.status.toUpperCase() === "ACTIVE" &&
            user.username !== "mariacolmenares25@gmail.com" /*  &&
            user.username !== "anami.vip@gmail.com" */
        );
        setUsersData(usersFilter);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFetchTreasuryUser = async () => {
    try {
      let users: any = null;
      users = await getRequestUser(0, "Tesorería");
      if (users) {
        let usersFilter = await users.content.filter(
          (user: any) =>
            user.status.toUpperCase() === "ACTIVE" &&
            user.username !== "mariacolmenares25@gmail.com" &&
            user.username !== "anami.vip@gmail.com"
        );
        setTreasuryUsersData(usersFilter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    const getBranch = async () => {
      await handleBranch();
    };
    if (dataBranch === null) {
      getBranch();
    }
    const getUser = async () => {
      await handleFetchUser();
      await handleFetchTreasuryUser();
    };
    if (dataBranch === null) {
      getBranch();
    }
    if (usersData === null) {
      getUser();
    }
  }, []);

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role,
        signatureType
      );
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchDocumentsRoleTreasury = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClientTreasury(
        id,
        personType,
        riskType,
        role
      );
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      return null;
    }
  };

  const handleAddClient = async (
    data: any,
    personType: string,
    riskType: string,
    role: number,
    signatureType: string
  ) => {
    setLoading && setLoading(true);
    //console.log(data);
    try {
      //call service
      let addData: any = await createRequest({
        ...data,
        creatorUser: authInfo?.username,
        code: 0,
        stage: data?.clientStatus === "1" ? { id: 1 } : { id: 7 },
        clientStatus: data.clientStatus,
        //office: JSON.stringify({ id: data.office.id, name: data.office.name }),
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      if (addData?.blackListVerification.some((bl: any) => bl.inBlackList)) {
        let names: string[] = [];
        await addData?.blackListVerification.map(
          (bl: any) => bl.inBlackList && names.push(bl.name)
        );

        setDetailsMsg &&
          setDetailsMsg(
            `Hemos detectado que ${
              names?.length > 1 ? "los clientes" : "el cliente"
            } <br/><strong style="color: #d32f2f">${addData?.blackListVerification
              .map((bl: any, i: number) =>
                bl.inBlackList === true &&
                i !== addData.blackListVerification.length - 1
                  ? bl.name.replace(/[^\w\s]/gi, "") + `<br>`
                  : bl.name.toString().replaceAll(",", "")
              )
              .flat()
              .join("")}</strong> <br/>se ${
              names?.length > 1 ? "encuentran" : "encuentra"
            } en la lista negra. <br/>Por favor comuníquese con cumplimiento`
          );
      }

      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);

      //navigate(`/createClient/${addData.id}`);
      navigate(`/createClient/${addData.clientDTO.id}`);
      //@ts-ignore
      handleFetchClient && handleFetchClient(addData.clientDTO.id);
      await handleFetchDocumentsRole(
        addData.clientDTO.id,
        personType,
        riskType,
        role,
        signatureType
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdateClientData = async (data: any, id: string) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        id: id,
        modifierUser: authInfo?.username,
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      handleFetchClient && handleFetchClient(data.id);
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const formContextValues: StepContextType = {
    activeStep,
    setActiveStep,
    handleBack,
    handleNext,
    handleReturn,
    client,
    setClient,
    handleFetchClient,
    updateDataClient,
    activeStepTreasury,
    setActiveStepTreasury,
    secondActiveStepTreasury,
    setSecondActiveStepTreasury,
    handleBackTreasury,
    handleNextTreasury,
    handleBackTreasurySecond,
    handleNextTreasurySecond,
    showSecondList,
    setshowSecondList,
    handleReturnTreasury,
    address,
    setAddress,
    dataJ,
    setAllDataJ,
    dataBranch,
    setDataBranch,
    usersData,
    treasuryUsersData,
    handleFetchDocumentsRole,
    handleFetchDocumentsRoleTreasury,
    handleAddClient,
    handleUpdateClientData,
  };

  return (
    <StepContext.Provider value={formContextValues}>
      {children}
    </StepContext.Provider>
  );
};

export { StepProvider };

export default StepContext;

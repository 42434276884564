import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import {
  AngleRightIcon,
  CirclePlusIcon,
} from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createClientsTypes, user } from "../types/types";
import { data } from "../resources/data/data";
import {
  createRequest,
  updateRequest,
  getRequestDocumentClient,
} from "../services/ManagementClientsService";
import { changeState } from "../services/StageAndFlowService";
import { properties } from "../utils/Properties_es";
import ExecutiveStage from "./stage/ExecutiveStage";
import ConfirmChangeState from "../components/utils/ConfirmChangeState";
import Observations from "../components/utils/Observations";
import ResourceAccess from "../components/security/ResourceAccess";
import DocumentStage from "./stage/DocumentsStage";
import { PatternFormat } from "react-number-format";

const CreateClient: FC = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    setDetailsMsg,
  } = useApp();
  const {
    setActiveStep,
    client,
    handleFetchClient,
    handleReturn,
    usersData,
    dataBranch,
  } = useStep();
  const { id } = useParams();
  const navigate = useNavigate();
  const [documentByClient, setDocumentByClient] = useState<any | null>(null);
  const [documentByClientAnalysis, setDocumentByClientAnalysis] = useState<
    any | null
  >(null);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [filterExecutive, setFilterExecutive] = useState<user[] | null>(null);

  useLayoutEffect(() => {
    const dataInit = async () => {
      if (id) {
        handleFetchClient && handleFetchClient(id);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role,
        signatureType
      );
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const accountSchema = {
    client: Yup.number(),
    name: Yup.string().required("Campo es requerido"),
    typeIdentification: Yup.string().required("Requerido"),
    identification: Yup.string()
      .required("Campo es requerido")
      .when("typeIdentification", {
        is: "Cédula",
        then: Yup.string().test(
          "ci",
          "Formato no es correcto",
          (value: any) =>
            value !== null && //@ts-ignore
            value.replaceAll("-", "").replace(/\s/g, "").length > 10 &&
            value.replaceAll("-", "").replace(/\s/g, "").length < 12
        ),
      })
      .when("typeIdentification", {
        is: "RNC",
        then: Yup.string().test(
          "ci",
          "Formato no es correcto",
          (value: any) =>
            value !== null && //@ts-ignore
            value.replaceAll("-", "").replace(/\s/g, "").length > 8 &&
            value.replaceAll("-", "").replace(/\s/g, "").length < 10
        ),
      })
      .when("typeIdentification", {
        is: "Pasaporte",
        then: Yup.string().test(
          "ci",
          "Formato no es correcto",
          (value: any) =>
            value !== null && //@ts-ignore
            value.replaceAll("-", "").replace(/\s/g, "").length > 7
        ),
      }),
    email: Yup.string().email("Email es invalido"),
    risk: Yup.string()
      .required("Campo es requerido")
      .typeError("Campo es requerido"),
  };

  const executiveSchema = {
    id: Yup.number().required("Campo es requerido"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    username: Yup.string(),
  };
  const branchSchema = {
    id: Yup.number().required("Campo es requerido"),
    notaryId: Yup.number(),
    address: Yup.string(),
    name: Yup.string(),
    tableName: Yup.string(),
    type: Yup.string(),
  };

  //Validación de datos
  const validation = Yup.object().shape({
    code: Yup.string(),
    creationtDate: Yup.date().typeError(""),
    creatorUser: Yup.string(),
    executive: Yup.object()
      .shape(executiveSchema)
      .typeError("Campo es requerido"),
    modificationDate: Yup.date().typeError(""),
    modifierUser: Yup.string(),
    personType: Yup.string()
      .required("Campo es requerido")
      .typeError("Campo es requerido"),
    office: Yup.object().shape(branchSchema).typeError("Campo es requerido"),
    /* office: Yup.string()
      .required("Campo es requerido")
      .typeError("Campo es requerido"), */
    typeAccount: Yup.string()
      .required("Campo es requerido")
      .typeError("Campo es requerido"),
    fileForm: Yup.string()
      .required("Campo es requerido")
      .typeError("Campo es requerido"),
    accounts: Yup.array()
      .of(Yup.object().shape(accountSchema))
      .when("typeAccount", {
        is: "Mancomunada",
        then: Yup.array()
          .of(Yup.object().shape(accountSchema))
          .min(2, "Mínimo 2 cuentas"),
      }),
  });

  const defaultValues: createClientsTypes = {
    id: client?.id ? client.id : "",
    code: client?.code ? client.code : 0,
    creationtDate: new Date(),
    creatorUser: client?.creatorUser ? client.creatorUser : authInfo?.username,
    executive: client?.executive ? client.executive : [],
    modificationDate: new Date(),
    modifierUser: client?.modifierUser ? client.modifierUser : "",
    personType: client?.personType ? client.personType : "Física",
    stage: client?.stage ? client.stage : "Identificación",
    //@ts-ignore
    office: client?.office ?? [],
    typeAccount: client?.typeAccount ?? "",
    fileForm: client?.fileForm ? client.fileForm : "",
    clientStatus: client?.clientStatus ? client.clientStatus : "1",
    accounts: (client?.accounts && client.accounts) ?? [
      {
        client: client?.id ?? 0,
        name: "",
        typeIdentification: "",
        identification: "",
        email: "",
        risk: "",
      },
    ],
    clientType: "DueDiligence",
  };
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    resetField,
    setValue,
  } = useForm<createClientsTypes>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors, isDirty } = formState;
  const {
    fields: addAccountsField,
    append: addAccountsAppend,
    remove: addAccountsRemove,
  } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 2,
    },
  });

  const [requiredDocuments, setRequiredDocuments] = useState(false);
  const [riskMore, setriskMore] = useState(null);
  const watch_personType: any = watch("personType");
  const watch_riskType: any = watch("accounts");
  const watch_typeAccount: any = watch("typeAccount");
  const watch_accounts: any = watch("accounts");
  const watch_clientStatus: any = watch("clientStatus");
  const watch_signatureType: any = watch("fileForm");
  const watch_office: any = watch("office");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    let riskMoreTall: any = null;
    watch_riskType.filter((c: any) => {
      if (c.risk.toLowerCase() === data.risk[3].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[2].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[1].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else {
        riskMoreTall = c;
        return true;
      }
    });
    //@ts-ignore
    riskMoreTall !== null &&
      riskMoreTall?.id > 0 &&
      setriskMore(riskMoreTall.risk);

    const dataDocuments = async () => {
      if (id && riskMoreTall !== null && riskMoreTall?.id > 0) {
        const loadDocuments = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          9,
          watch_signatureType
        );
        setDocumentByClient(loadDocuments);

        const loadDocumentsAnalysis = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          24,
          watch_signatureType
        );
        setDocumentByClientAnalysis(loadDocumentsAnalysis);
      }
    };
    dataDocuments();
    // eslint-disable-next-line
  }, [watch_personType, watch_riskType]);

  useEffect(() => {
    if (watch_personType === "Física" && isDirty) {
      resetField("typeAccount", {
        defaultValue: "",
      });
    }
    if (watch_personType === "Jurídica") {
      setValue("typeAccount", "Jurídicas");
    }
    // eslint-disable-next-line
  }, [watch_personType]);

  useEffect(() => {
    if (watch_office.id !== 0) {
      const filterUser: user[] | undefined = usersData?.filter(
        (item: user) => Number(item.userBranchOffice) === watch_office.id
      );
      if (filterUser) {
        if (!client?.executive || client?.office.id !== watch_office.id) {
          setValue("executive", {
            id: 0,
            username: "",
            firstName: "",
            lastName: "",
            phone: 0,
            status: "",
            userRole: "",
            activationCode: "",
            userBranchOffice: "",
          });
        }
        setFilterExecutive(filterUser);
      }
    }
  }, [watch_office]);

  /**
   * Metodo encargado de agregar registro   *
   * @param data
   */
  const handleAddClient = async (data: any) => {
    setLoading && setLoading(true);
    //console.log(data);
    try {
      //call service
      let addData: any = await createRequest({
        ...data,
        creatorUser: authInfo?.username,
        code: 0,
        stage: data?.clientStatus === "1" ? { id: 1 } : { id: 7 },
        clientStatus: data.clientStatus,
        //office: JSON.stringify({ id: data.office.id, name: data.office.name }),
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      if (addData?.blackListVerification.some((bl: any) => bl.inBlackList)) {
        let names: string[] = [];
        await addData?.blackListVerification.map(
          (bl: any) => bl.inBlackList && names.push(bl.name)
        );

        setDetailsMsg &&
          setDetailsMsg(
            `Hemos detectado que ${
              names?.length > 1 ? "los clientes" : "el cliente"
            } <br/><strong style="color: #d32f2f">${addData?.blackListVerification
              .map((bl: any, i: number) =>
                bl.inBlackList === true &&
                i !== addData.blackListVerification.length - 1
                  ? bl.name.replace(/[^\w\s]/gi, "") + `<br>`
                  : bl.name.toString().replaceAll(",", "")
              )
              .flat()
              .join("")}</strong> <br/>se ${
              names?.length > 1 ? "encuentran" : "encuentra"
            } en la lista negra. <br/>Por favor comuníquese con cumplimiento`
          );
      }

      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);

      //navigate(`/createClient/${addData.id}`);
      navigate(`/createClient/${addData.clientDTO.id}`);
      //@ts-ignore
      handleFetchClient && handleFetchClient(addData.clientDTO.id);
      await handleFetchDocumentsRole(
        addData.clientDTO.id,
        watch_personType,
        watch_riskType,
        9,
        watch_signatureType
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdateClientData = async (data: any) => {
    setLoading && setLoading(true);
    try {
      //call service
      let addData = await updateRequest({
        ...data,
        id: id,
        modifierUser: authInfo?.username,
      });

      if (!addData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_client_update_error);
        setLoading && setLoading(false);
        return;
      }
      handleFetchClient && handleFetchClient(data.id);
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangeState = async () => {
    let changeStateData: any = false;

    if (
      documentByClient &&
      documentByClient.some(
        (d: any) =>
          (client.personType === "Física" &&
            d.requiredPhysical === true &&
            d.uploaded < 1) ||
          (client.personType === "Jurídica" &&
            d.requiredJuridic === true &&
            d.uploaded < 1)
      )
    ) {
      setErrorMsg && setErrorMsg("Hay algunos documentos requeridos vacíos");
      setRequiredDocuments(true);
    } else {
      await handleUpdateClientData(client);
      try {
        if (client?.stage?.id === 1) {
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: 9,
            user: authInfo?.username,
          });
        }
        if (client?.stage?.id === 5) {
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: 6,
            user: authInfo?.username,
          });
          if (id) {
            handleFetchClient && handleFetchClient(id);
          }
        }
        if (
          (client?.stage?.id === 6 && client?.status?.id === 2) ||
          (client?.stage?.id === 7 && client?.status?.id === 11) ||
          (client?.stage?.id === 7 && client?.status?.id === 1) ||
          (client?.stage?.id === 7 && client?.status?.id === 3)
        ) {
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: 1,
            user: authInfo?.username,
          });
          if (id) {
            handleFetchClient && handleFetchClient(id);
          }
        }
        if (client?.stage?.id === 7 && client?.status?.id === 2) {
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: 1,
            user: authInfo?.username,
          });
          if (id) {
            handleFetchClient && handleFetchClient(id);
          }
        }
        if (
          (client?.stage?.id === 7 && client?.status?.id === 5) ||
          (client?.stage?.id === 8 && client?.status?.id === 2)
        ) {
          changeStateData = await changeState({
            clientId: Number(id),
            statusId: 3,
            user: authInfo?.username,
          });
          if (id) {
            handleFetchClient && handleFetchClient(id);
          }
        }
        if (!changeStateData) {
          setErrorMsg && setErrorMsg("Error al actualizar fase");
          setLoading && setLoading(false);
          return;
        }
        //setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading && setLoading(false);
        setSuccessMsg && setSuccessMsg("Estado actualizado");
        if (id) {
          handleFetchClient && handleFetchClient(id);
        }
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleCloseNotes = () => {
    setOpenNotesDialog(false);
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();

    const typeAction = e.target.name;
    switch (typeAction) {
      case "create":
        await handleAddClient(data);
        break;
      case "update":
        await handleUpdateClientData(data);
        break;
      case "updatestage":
        await handleChangeState();
        break;
      default:
        break;
    }
  };

  const typeIdentification = ["Cédula", "Pasaporte"];

  return (
    <>
      <form>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
            Crear cliente
            <br />
            <small style={{ fontSize: "1rem" }}>
              <b>Creado por:</b> {client?.creatorUser}
            </small>
            <br />
            <small style={{ fontSize: "1rem" }}>
              <b>Modificado por:</b> {client?.modifierUser}
            </small>
          </Typography>
          <Typography variant="h5" color="greyDue.dark" sx={{ mb: 4 }}>
            <strong>{client?.stage?.name}</strong> {client?.stage && "/ "}
            <small>{client?.flow?.name}</small>
          </Typography>
        </Stack>
        <Grid container spacing={6} maxWidth="lg" alignItems="flex-end">
          <Grid item md={6} sm={4} xs={12}>
            <Typography
              variant="h5"
              color="greyDue.dark"
              sx={{ fontWeight: 400, fontSize: "1.25rem" }}
            >
              <AngleRightIcon
                sx={{
                  marginRight: "0px",
                  color: "secondary.main",
                  transform: "translateY(3px)",
                  fontSize: "1.25rem",
                }}
              />{" "}
              Datos del cliente
            </Typography>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              control={control}
              name="office"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    dataBranch ?? [
                      {
                        address: "",
                        id: 0,
                        name: "",
                        notaryId: 0,
                        tableName: "",
                        type: "",
                      },
                    ]
                  }
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  sx={{ pb: 0 }}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Sucursal"
                        variant="standard"
                        error={!!errors.office}
                        helperText={errors.office && errors.office.message}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              name={"clientStatus"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Tipo de cliente</InputLabel>
                  <Select
                    value={value}
                    {...register("clientStatus")}
                    disabled={id ? true : false}
                  >
                    <MenuItem value="">Seleccione</MenuItem>
                    <MenuItem value="1">Cliente nuevo</MenuItem>
                    <MenuItem value="7">Cliente actualización</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ pt: 4 }} />
        <Grid container spacing={6} maxWidth="lg">
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"code"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Código del cliente"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  disabled={watch_clientStatus !== "7"}
                  {...register("code")}
                  error={errors.code && Boolean(errors.code)}
                  helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Controller
              control={control}
              name="executive"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    filterExecutive ?? [
                      {
                        id: 0,
                        username: "",
                        firstName: "",
                        lastName: "",
                        phone: 0,
                        status: "",
                        userRole: "",
                        activationCode: "",
                        userBranchOffice: "",
                      },
                    ]
                  }
                  getOptionLabel={(option: any) =>
                    filterExecutive && option.firstName
                      ? option.firstName + " " + option.lastName
                      : ""
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value ?? []}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  sx={{ pb: 0 }}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Ejecutivo"
                        variant="standard"
                        error={!!errors.office}
                        helperText={errors.office && errors.office.message}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="personType"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={data.personType}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de persona"
                        variant="standard"
                        error={!!errors.personType}
                        helperText={
                          errors.personType && errors.personType.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="fileForm"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={data.fileForm}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0")
                  }
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Modalidad de Firma"
                        variant="standard"
                        error={!!errors.fileForm}
                        helperText={errors.fileForm && errors.fileForm.message}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Controller
              control={control}
              name="typeAccount"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    watch_personType === "Física"
                      ? data.typeAccount
                      : data.typeAccountLegal
                  }
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled={
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      false ||
                    (client !== null &&
                      client?.code !== 0 &&
                      client?.code !== "0") ||
                    watch_personType === "Jurídica"
                  }
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de cuenta"
                        variant="standard"
                        error={!!errors.typeAccount}
                        helperText={
                          errors.typeAccount && errors.typeAccount.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {addAccountsField.map((field, index) => {
          return (
            <div key={index}>
              <Divider sx={{ pt: 3, mb: 3 }} />
              <Grid container spacing={6} maxWidth="lg">
                <Grid item xs={3} md={1}>
                  <Controller
                    name={`accounts.${index}.typeIdentification`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth variant="standard">
                        <Select
                          value={value}
                          {...register(
                            `accounts.${index}.typeIdentification` as const
                          )}
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "4px 0 10px",
                            },

                            marginTop: "16px",
                          }}
                          error={
                            errors?.accounts?.[index]?.typeIdentification &&
                            true
                          }
                          disabled={
                            ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                              false ||
                            (client !== null &&
                              client?.code !== 0 &&
                              client?.code !== "0")
                          }
                        >
                          {watch_personType === "Física" ? (
                            typeIdentification.map((m: any, i: number) => (
                              <MenuItem value={m} key={i}>
                                {m}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="RNC">RNC</MenuItem>
                          )}
                        </Select>
                        <FormHelperText error={true}>
                          {
                            errors?.accounts?.[index]?.typeIdentification
                              ?.message
                          }
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={9}>
                  <Controller
                    name={`accounts.${index}.identification`}
                    control={control}
                    render={({ field: { onChange, name, value } }) =>
                      watch_accounts[index].typeIdentification !==
                      "Pasaporte" ? (
                        <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                          <PatternFormat
                            className="input-due"
                            name={name}
                            onPaste={(e: any) => e.preventDefault()}
                            mask="_"
                            format={
                              watch_accounts[index].typeIdentification ===
                              "Cédula"
                                ? "###-#######-#"
                                : "#-##-#####-#"
                            }
                            value={value}
                            onChange={onChange}
                            placeholder={
                              watch_accounts[index].typeIdentification ===
                              "Cédula"
                                ? "###-#######-#"
                                : "#-##-#####-#"
                            }
                            disabled={
                              ResourceAccess(
                                true,
                                "IDENTIFICATIONSTEP:WRITE"
                              ) === false ||
                              (client !== null &&
                                client?.code !== 0 &&
                                client?.code !== "0")
                            }
                          />
                        </FormControl>
                      ) : (
                        <TextField
                          type="text"
                          variant="standard"
                          fullWidth
                          label=""
                          value={value}
                          sx={{
                            marginTop: "14px",
                            "&.Mui-disabled:before": {
                              borderBottomStyle: "dotted!important",
                            },
                          }}
                          disabled={
                            ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                              false ||
                            (client !== null &&
                              client?.code !== 0 &&
                              client?.code !== "0")
                          }
                          {...register(
                            `accounts.${index}.identification` as const
                          )}
                          error={
                            errors?.accounts?.[index]?.identification && true
                          }
                        />
                      )
                    }
                  />
                  <FormHelperText>
                    {errors?.accounts?.[index]?.identification?.message}
                  </FormHelperText>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Controller
                    name={`accounts.${index}.name`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Nombres y Apellidos"
                        type="text"
                        variant="standard"
                        fullWidth
                        value={value}
                        disabled={
                          ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                            false ||
                          (client !== null &&
                            client?.code !== 0 &&
                            client?.code !== "0")
                        }
                        {...register(`accounts.${index}.name` as const)}
                        error={errors?.accounts?.[index]?.name && true}
                        helperText={errors?.accounts?.[index]?.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Controller
                    name={`accounts.${index}.email`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Email"
                        type="mail"
                        variant="standard"
                        fullWidth
                        value={value}
                        disabled={
                          ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                            false ||
                          (client !== null &&
                            client?.code !== 0 &&
                            client?.code !== "0")
                        }
                        {...register(`accounts.${index}.email` as const)}
                        error={errors?.accounts?.[index]?.email && true}
                        helperText={errors?.accounts?.[index]?.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ display: "flex" }}>
                  <Controller
                    control={control}
                    name={`accounts.${index}.risk`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        fullWidth
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={data.risk}
                        getOptionLabel={(option: any) => option || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option === value
                        }
                        //@ts-ignore
                        value={value}
                        disabled={
                          ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                            false ||
                          (client !== null &&
                            client?.code !== 0 &&
                            client?.code !== "0")
                        }
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Riesgo"
                              variant="standard"
                              error={errors?.accounts?.[index]?.risk && true}
                              helperText={
                                errors?.accounts?.[index]?.risk?.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                  {index > 0 &&
                    (client === null || client?.code === "") &&
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      true && (
                      <FancyTooltip title="Remover cuenta" placement="top">
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            addAccountsRemove(index);
                          }}
                        >
                          <RemoveCircleIcon sx={{ fontSize: "1.563rem" }} />
                        </IconButton>
                      </FancyTooltip>
                    )}
                </Grid>
              </Grid>
            </div>
          );
        })}
        {addAccountsField.length < 4 &&
          watch_typeAccount === "Mancomunada" &&
          ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") === true &&
          client === null && (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <FancyTooltip title="Agregar nueva cuenta" placement="top">
                <IconButton
                  sx={{
                    color: "#198754",
                  }}
                  aria-label="add"
                  component="label"
                  onClick={() => {
                    addAccountsAppend({
                      client: id ? Number(id) : 0,
                      name: "",
                      typeIdentification: "",
                      identification: "",
                      email: "",
                      risk: "",
                    });
                  }}
                >
                  <CirclePlusIcon sx={{ fontSize: "1.563rem" }} />
                </IconButton>
              </FancyTooltip>
            </Grid>
          )}
        {errors?.accounts && (
          <FormHelperText sx={{ fontSize: "1rem" }}>
            {errors.accounts.message}
          </FormHelperText>
        )}
        <Grid container spacing={6} maxWidth="lg" sx={{ pt: 6, pb: 8 }}>
          {id && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                data-name="observation"
                onClick={() => setOpenNotesDialog(true)}
              >
                Ver observaciones
              </Button>
            </Grid>
          )}
        </Grid>
        {!id && (
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 4 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReturn}
            >
              Regresar
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mr: 2,
              }}
              data-name="create"
              name="create"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </Stack>
        )}
      </form>
      {id && (
        <>
          {client?.stage?.id !== 7 ? (
            <ExecutiveStage
              personTypeData={watch_personType}
              disabledByStage={
                ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") === false ||
                (client?.stage?.id !== 1 && client?.stage?.id !== 6) ||
                (client?.stage?.id === 6 && client?.status?.id !== 2)
              }
              riskTypeData={riskMore}
              documentByClient={documentByClient}
              handleFetchDocumentsRole={handleFetchDocumentsRole}
              handleFetchClient={handleFetchClient}
              client={client}
              requiredDocuments={requiredDocuments}
            />
          ) : (
            <ExecutiveStage
              personTypeData={watch_personType}
              disabledByStage={
                ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") === false
              }
              riskTypeData={riskMore}
              documentByClient={documentByClient}
              handleFetchDocumentsRole={handleFetchDocumentsRole}
              handleFetchClient={handleFetchClient}
              client={client}
              requiredDocuments={requiredDocuments}
            />
          )}

          <DocumentStage
            personTypeData={watch_personType}
            riskTypeData={riskMore}
            documentByClient={documentByClientAnalysis}
            handleFetchDocumentsRole={handleFetchDocumentsRole}
            handleFetchClient={handleFetchClient}
            client={client}
            requiredDocuments={requiredDocuments}
            idRole="24"
            title="Categoría Análisis y Monitoreo"
          />
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 4 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReturn}
              data-write={ResourceAccess(true, "CHANGESTAGE:WRITE")}
            >
              Regresar
            </Button>
            {ResourceAccess(true, "AUTHORITYTOCHANGESTAGEINSTEP:WRITE") &&
              (client?.stage?.id === 1 ||
                client?.stage?.id === 5 ||
                client?.stage?.id === 6 ||
                client?.stage?.id === 7 ||
                client?.stage.id === 8) && (
                <>
                  {isDirty && (
                    <Button
                      variant="contained"
                      color="primary"
                      data-name="update"
                      name="update"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar
                    </Button>
                  )}
                  {!isDirty &&
                    (client.stage.id === 1 ||
                      client.stage.id === 6 ||
                      client.stage.id === 7 ||
                      client.stage.id === 8 ||
                      client.stage.id === 9) &&
                    ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                      true && (
                      <Button
                        variant="contained"
                        data-name="updatestage"
                        name="updatestage"
                        onClick={handleSubmit(onSubmit)}
                        color="primary"
                        disabled={
                          (client?.stage.id === 6 &&
                            client?.status.id === 6) /* ||
                          (client?.stage.id === 7 && client?.status.id === 1) */ ||
                          (client?.stage.id === 8 && client?.status.id === 1)
                        }
                      >
                        Guardar y Enviar
                      </Button>
                    )}
                </>
              )}
          </Stack>
        </>
      )}
      {modalData?.modalType === "updatestage" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <ConfirmChangeState
            textChildren="Si cambias de estado no podras agregar o borrar los documentos cargados."
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  name="updatestage"
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}

      <Dialog
        open={openNotesDialog}
        onClose={() => setOpenNotesDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <Observations id={id ? id : ""} closeModal={handleCloseNotes} />
      </Dialog>
    </>
  );
};

export default CreateClient;

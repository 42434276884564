import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import Dashboard from "../layouts/Dashboard";
import ManagementClients from "../pages/ManagementClients";
import BulkUploadCustomerDocs from "../pages/BulkUploadCustomerDocs";
import CreatedClient from "../pages/CreatedClient";
import UpdatedDueClient from "../pages/UpdatedDueClient";
import LegalRegistry from "../pages/treasury/LegalRegistry";
import ProfileInvertor from "../pages/treasury/ProfileInverstor";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import StepsClient from "../pages/StepsClient";

const Session = lazy(() => import("../pages/session/Session"));

const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<Dashboard />}>
        <Route index element={<ManagementClients />} />
        <Route
          path="/bulk-upload-customer-docs"
          element={<BulkUploadCustomerDocs />}
        />
        <Route
          path="/bulk-upload-customer-docs/:id"
          element={<BulkUploadCustomerDocs />}
        />
        <Route path="/createdClient/:id" element={<CreatedClient />} />
        <Route path="/updatedDueClient/:id" element={<UpdatedDueClient />} />
        {/*     <Route path="/legalregistry" element={<LegalRegistry />} />
        <Route path="/legalregistry/:id" element={<ProfileInvertor />} /> */}
        <Route path="/createClient" element={<StepsClient />} />
        <Route path="/createClient/:id" element={<StepsClient />} />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);

import { useState, useEffect, createContext } from "react";
import { Dispatch, SetStateAction } from "react";
import {
  getRequestNote,
  saveNoteAndImageRequest,
  deleteRequestNote,
} from "../services/ObservationsService";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";

interface NotesContextType {
  notesData: any;
  setNotesData?: Dispatch<SetStateAction<any>>;
  handleFetchNotes?: (id: string, currentPage: number) => void;
  handleAddNotes?: (data: any, idClient: string, success?: any) => void;
  handleDeleteNote?: (id: any) => void;
  handleChangePage?: (
    event: unknown,
    newPage: number,
    idClient: string
  ) => void;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  idClient?: string;
  setIdClient?: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const initialState = {
  notesData: null,
  loading: false,
};

const NotesContext = createContext<NotesContextType>(initialState);

const NotesProvider = ({ children }: any) => {
  const [notesData, setNotesData] = useState(initialState.notesData);
  const [loading, setLoading] = useState(initialState.loading);
  const [page, setPage] = useState(0);
  const [idClient, setIdClient] = useState("");

  const {
    authInfo,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleFetchNotes = async (id: string, currentPage: number) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage);
      let data: any = await getRequestNote(id, currentPage);
      if (data) {
        setNotesData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
    }
  };

  const handleAddNotes = async (data: any, idClient: string, success?: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    let addData: any = null;

    try {
      const formData = new FormData();
      if (data.file) {
        if (data?.file[0]) {
          formData.append("file", data.file[0]);
        }
      }

      let dataClient: any = {
        clientId: data.idClient,
        createdBy: authInfo?.username,
        description: data.description,
      };
      for (const key in dataClient) {
        if (key !== "file") {
          formData.append(key, dataClient[key]);
        }
      }

      addData = await saveNoteAndImageRequest(formData);

      if (!addData) {
        setErrorMsg && setErrorMsg("Error en proceso de guardar observación");
        setLoading && setLoading(false);
        return;
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(
          success !== "" ? success : properties.com_parval_label_request_save
        );

      await handleFetchNotes(idClient, 0);
    } catch (error: any) {
      console.log(error);
      //setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }

    return addData;
  };

  const handleDeleteNote = async (id: any) => {
    handleCancelModal();
    setLoading(true);
    try {
      let deleteData = await deleteRequestNote({
        id: id,
        user: authInfo.username,
      });
      if (!deleteData) {
        setErrorMsg && setErrorMsg("Error borrando observación");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      await handleFetchNotes(idClient, 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    let customPage = newPage - 1;
    if (customPage !== page) {
      handleFetchNotes && handleFetchNotes(idClient, customPage);
    }
  };

  const formContextValues: NotesContextType = {
    notesData,
    page,
    handleFetchNotes,
    handleAddNotes,
    handleDeleteNote,
    handleChangePage,
    setIdClient,
    loading,
  };

  return (
    <NotesContext.Provider value={formContextValues}>
      {children}
    </NotesContext.Provider>
  );
};

export { NotesProvider };

export default NotesContext;

import { URL } from "../utils/UrlConstant";
import { privateFetch } from "../utils/CustomFetch.js";

export const getRequestUser = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_USER_LIST(page, 100), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

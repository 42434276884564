import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  styled,
  useTheme,
  useMediaQuery,
  Box,
  Paper,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import CreateClient from "./CreateClient";
import CreateClientCompliance from "./CreateClientCompliance";
import CreateClientQualification from "./CreateClientQualification";
import CreateClienteCreation from "./CreateClienteCreation";
import FancyTooltip from "../components/utils/FancyTooltip";
import Loading from "../components/utils/Loading";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import ResourceAccess from "../components/security/ResourceAccess";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "inherit",
  ...(ownerState.active && {
    backgroundColor: `${theme.palette.secondary.dark}`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.35)",
  }),
  ...(ownerState.completed && {
    backgroundColor: `${theme.palette.secondary.dark}`,
  }),
}));

const WrappMobileStepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.dark}`,
  color: "#fff",
  fontFamily: "Mulish",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 25 }} />,
    2: <BadgeIcon sx={{ fontSize: 25 }} />,
    3: <PeopleAltIcon sx={{ fontSize: 25 }} />,
    4: <PeopleAltIcon sx={{ fontSize: 25 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ cursor: "pointer" }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

interface StepsType {
  label: string;
  component: JSX.Element;
}

const steps: StepsType[] = [
  {
    label: "Identificación",
    component: <CreateClient />,
  },
  {
    label: "Cumplimiento",
    component: <CreateClientCompliance />,
  },
  {
    label: "Calificar",
    component: <CreateClientQualification />,
  },
  {
    label: "Creación",
    component: <CreateClienteCreation />,
  },
];

const StepsClient = () => {
  const { authInfo, isLoading } = useApp();
  const { client, activeStep, setActiveStep } = useStep();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step);
  };

  const StepTemplate = (id: number, code: string) => {
    return (
      <>
        {ResourceAccess(true, `${code}`) === false ||
        (authInfo.userRole.id === 11 && client?.stage?.id <= 5) ||
        client?.stage?.id >= 6 ? (
          <FancyTooltip
            title="Usuario no tiene permisos de ver este paso"
            placement="top"
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {steps[id].label}
            </StepLabel>
          </FancyTooltip>
        ) : (
          <StepButton onClick={handleStep(id)}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {steps[id].label}
            </StepLabel>
          </StepButton>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{ px: 3.75, pt: 12, pb: 12, mr: "auto", ml: "auto" }}
        maxWidth="lg"
      >
        <Paper
          sx={{
            padding: {
              xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
              md: theme.spacing(10),
            },
            borderRadius: theme.spacing(4),
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className={`${activeStep}`}
          >
            <Step>{StepTemplate(0, "IDENTIFICATIONSTEP:READ")}</Step>
            <Step disabled={false}>
              {StepTemplate(1, "COMPLIANCESTEP:READ")}
            </Step>
            <Step disabled={false}>{StepTemplate(2, "QUALIFYSTEP:READ")}</Step>
            <Step disabled={false}>{StepTemplate(3, "CREATIONSTEP:READ")}</Step>
          </Stepper>
          <Box
            sx={{ px: 3.75, pt: 12, pb: 0, mr: "auto", ml: "auto" }}
            maxWidth="lg"
          >
            <Paper
              sx={{
                padding: {
                  xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
                  md: theme.spacing(10),
                },
                pb: theme.spacing(2),
                borderRadius: theme.spacing(4),
              }}
            >
              {steps[activeStep].component}
            </Paper>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default StepsClient;

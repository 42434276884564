/*****************************************************************************
 * REVISION 1.0
 * Fecha: 22/12/2022
 *
 * @description JS para manejo de labels
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General label */
  com_parval_label_yes: "Si",
  com_parval_label_not: "No",
  com_parval_label_add_subtitle: "Ingrese los datos indicados",
  com_parval_label_update_subtitle: "Modifique los datos indicados",
  com_parval_label_code: "Código",
  com_parval_label_codetfa: "Código de validación",
  com_parval_label_phone: "Teléfono",
  com_parval_label_url: "Url",
  com_parval_label_category: "Categoría",
  com_parval_label_name: "Nombre",
  com_parval_label_lastname: "Apellido",
  com_parval_label_state: "Estado",
  com_parval_label_identification: "Cédula",
  com_parval_label_role: "Rol",
  com_parval_label_createat: "Fecha de creación",
  com_parval_label_createat2: "Fecha solicitud",
  com_parval_label_email: "Correo electrónico",
  com_parval_label_password: "Contraseña",
  com_parval_label_passwordrepeat: "Repita contraseña",
  com_parval_label_id: "ID",
  com_parval_label_paymentType: "Tipo de pago",
  com_parval_label_card: "Tarjeta",
  com_parval_label_description: "Descripción",
  com_parval_label_price: "Precio",
  com_parval_label_subtotal: "SubTotal",
  com_parval_label_total: "Total",
  com_parval_label_tax: "ITBIS (18%)",
  com_parval_label_taxes: "Impuesto",
  com_parval_label_department: "Departamento",
  com_parval_label_generationtime: "Tiempo generación",
  com_parval_label_comment: "Comentario",
  com_parval_label_free: "Gratis",
  com_parval_label_zero: "0",
  com_parval_label_select: "Seleccione",
  com_parval_label_select_other: "Otro",
  com_parval_label_not_apply: "No aplica",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght:number) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght:number) =>
    `Longitud minima permitida (${lenght})`,
  /** General sucess labels in process */
  com_parval_label_request_save: "Registro insertado con éxito",
  com_parval_label_request_update: "Registro actualizado con éxito",
  com_parval_label_request_delete: "Registro eliminado con éxito",
  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

  /** General button labels */
  com_parval_label_button_accept_title: "Aceptar",
  com_parval_label_button_ok_title: "Ok",
  com_parval_label_button_cancel_title: "Cancelar",
  com_parval_label_button_save_title: "Registrar",
  com_parval_label_button_update_title: "Editar",
  com_parval_label_button_detail_title: "Ver detalle",
  com_parval_label_button_changestate_title: "Cambiar estado",
  com_parval_label_button_delete_title: "Eliminar",
  com_parval_label_button_back_title: "Atrás",
  com_parval_label_button_back2_title: "Volver",
  com_parval_label_button_add_title: "Agregar",
  com_parval_label_button_search_title: "Buscar",
  com_parval_label_button_clear_title: "Limpiar",
  com_parval_label_button_login_title: "Iniciar sesión",
  com_parval_label_button_logout_title: "Cerrar sesión",
  com_parval_label_button_view_title: "Ver",
  com_parval_label_button_exit_title: "Salir",
  com_parval_label_button_forgotpass_title: "Recuperar contraseña",
  com_parval_label_button_updatepass_title: "Actualizar contraseña",

  /** Table label */
  com_parval_label_table_filter_title: "Filtrar",
  com_parval_label_table_filter_title2: "Filtrar por:",
  com_parval_label_table_filter_placeholder: "Filtro de búsqueda",
  com_parval_label_table_action: "Acciones",
  com_parval_label_table_empty: "No existen registros",

  /** General modal label  */
  com_parval_label_modal_delete_title: "Eliminar Registro",

  /** Header dashboard label */
  com_parval_label_dashboard_header_title: "Gestiones en Línea",
  /** Menu dashboard label */
  com_parval_label_dashboard_menu_principal: "Portal Administrativo",
  com_parval_label_dashboard_menu_inicio: "Inicio",
  com_parval_label_dashboard_menu_logout: "Cerrar sesión",
  com_parval_label_dashboard_menu_management: "Gestiones",
  /** maintenance admin menu */
  com_parval_label_dashboard_menu_maintenance: "Mantenimientos",
  com_parval_label_dashboard_menu_maintenance_certificatecategory:
    "Categoría certificado",
  com_parval_label_dashboard_menu_maintenance_certificate: "Certificado",
  /** configuration admin menu */
  com_parval_label_dashboard_menu_config: "Configuraciones",
  com_parval_label_dashboard_menu_config_user: "Usuarios",

  /** login */
  com_parval_label_login_title: "Portal Administrativo",
  com_parval_label_login_forget_password_title: "¿Olvidaste tu contraseña?",
  com_parval_label_login_portal_select_title: "Seleccione portal",
  com_parval_label_login_portal_dd_title: "Due Diligence",
  com_parval_label_login_portal_gl_title: "Gestiones en Línea",
  com_parval_label_login_portal_op_title: "Operaciones",
  /** login success*/
  com_parval_label_login_twofactor_success:
    "Código de validación enviado al correo",
  /** login error*/
  com_parval_label_login_twofactor_error:
    "Error en proceso de enviar código de validación", 
  com_parval_label_login_error2:
    "Usuario no tiene permiso de loguearse en este sitio",
  com_parval_label_login_general_error:
    "Error en proceso de ingresar al portal administrativo",
  com_parval_label_login_logout_error: "Error en proceso de cerrar sesion",

  /** RecoveryPassword */
  com_parval_label_recoverypass_title: "Recuperar Contraseña",
  com_parval_label_recoverypass_back_title: "Regresar a login",
  /** ForgotPassword success*/
  com_parval_label_recoverypass_general_sucess:
    "El correo de recuperación a sido enviado",
  /** ForgotPassword error*/
  com_parval_label_recoverypass_general_error:
    "Error en proceso de recuperar contraseña",

  /** ForgotPassword */
  com_parval_label_forgotpass_title: "Cambiar Contraseña",
  /** ForgotPassword success*/
  com_parval_label_forgotpass_general_sucess:
    "El cambio de contraseña se completo",
  /** ForgotPassword error*/
  om_parval_label_forgotpass_different_password_error:
    "Las contraseñas no coinciden",
  com_parval_label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",

  /** ActiveRegister */
  com_parval_label_activeregister_title: "Activación Usuario",
  /** ActiveRegister success*/
  com_parval_label_activeregister_general_sucess:
    "La activación del usuario se completó",
  com_parval_label_activeregister_general_sucess2:
    "La activación del usuario se completó correctamente.",
  /** ActiveRegister error*/
  com_parval_label_activeregister_general_error:
    "Error en proceso de activar usuario",

  /** CertificateCategory */
  com_parval_label_certificatecategory_button_add_title: "Agregar categoría",
  com_parval_label_certificatecategory_add_title:
    "Agregar categoría certificado",
  com_parval_label_certificatecategory_update_title:
    "Editar categoría certificado",

  /** CertificateCategory service error label */
  com_parval_label_certificatecategory_find_error:
    "Error en consulta de categorías de certificado",
  com_parval_label_certificatecategory_findid_error:
    "Error en consulta por id de categoría de certificado",
  com_parval_label_certificatecategory_save_error:
    "Error en proceso de guardar categoría de certificado",
  com_parval_label_certificatecategory_update_error:
    "Error en proceso de actualizar categoría de certificado",
  com_parval_label_certificatecategory_delete_error:
    "Error en proceso de eliminar categoría de certificado",

  /** Certificate */
  com_parval_label_certificate_button_add_title: "Agregar certificado",
  com_parval_label_certificate_add_title: "Agregar certificado",
  com_parval_label_certificate_update_title: "Editar certificado",
  com_parval_label_certificate_generationtime_placeholder_desc:
    "Ingrese tiempo en minutos (Ejemplo: 60 = 1 hora)",
  com_parval_label_certificate_generationtime_desc_format: (h:any, m:any) =>
    `${h}(h):${m}(m)`,

  /** Clients */
  com_parval_label_client_button_add_title: "Agregar cliente",
  com_parval_label_client_add_title: "Agregar cliente",
  com_parval_label_client_update_title: "Editar cliente",
  com_parval_label_client_update_pass_title: "Actualizar contraseña de cliente",
  /** clients service error label */
  com_parval_label_client_find_error: "Error en consulta de clientes",
  com_parval_label_client_findid_error: "Error en consulta por id de cliente",
  com_parval_label_client_save_error: "Error en proceso de guardar cliente",
  com_parval_label_client_update_error: "Error en proceso de actualizar cliente",
  com_parval_label_client_delete_error: "Error en proceso de eliminar cliente",

  /** Roles */
  /** Roles service error label */
  com_parval_label_role_find_error: "Error en consulta de roles",


  /** Management */
  com_parval_label_selectstatus: "Seleccione estado",
  com_parval_label_management_status_slc_title:"Solicitado",
  com_parval_label_management_status_prc_title:"Proceso",
  com_parval_label_management_status_fin_title:"Finalizado",
  com_parval_label_management_status_fac_title:"Facturado",
  com_parval_label_management_status_del_title:"Eliminado",
  com_parval_label_management_status_unknown_title:"Desconocido",

  /** Management service error label */
  com_parval_label_management_find_error: "Error en consulta de gestiones",
  com_parval_label_management_findid_error:
    "Error en consulta por id de gestión",
  com_parval_label_management_save_error:
    "Error en proceso de guardar gestión",
  com_parval_label_management_update_error:
    "Error en proceso de actualizar gestión",
  com_parval_label_management_delete_error:
    "Error en proceso de eliminar gestión",

  /** APP LOGIN MSG*/
  com_parval_label_applogin_error:
    "Error en proceso de inicio de sesión de aplicación",
  com_parval_label_login_sessioncode_error:
    "Error en proceso de validar el código de sesión",
  com_parval_label_login_error:
    "Error en proceso de inicio de sesión",

  /** Document service error label */
  com_parval_label_document_find_error: "Error en consulta de documento",
  com_parval_label_document_findid_error:
    "Error en consulta por id de documento",
  com_parval_label_document_save_error:
    "Error en proceso de guardar documento",
  com_parval_label_document_update_error:
    "Error en proceso de actualizar documento",
  com_parval_label_document_delete_error:
    "Error en proceso de eliminar documento",  

};

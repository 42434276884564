import * as Yup from "yup";

export const executiveSchema = {
  id: Yup.number().required("Campo es requerido"),
  firstName: Yup.string(),
  lastName: Yup.string(),
  username: Yup.string(),
};
export const branchSchema = {
  id: Yup.number().required("Campo es requerido"),
  notaryId: Yup.number(),
  address: Yup.string(),
  name: Yup.string(),
  tableName: Yup.string(),
  type: Yup.string(),
};

export const accountSchema = {
  client: Yup.number(),
  name: Yup.string().required("Campo es requerido"),
  typeIdentification: Yup.string().nullable(),
  //identification: Yup.string().required("Campo es requerido"),
  identification: Yup.string()
    .required("Campo es requerido")
    .when("typeIdentification", {
      is: "Cédula",
      then: Yup.string().test(
        "ci",
        "Formato no es correcto",
        (value: any) =>
          value !== null && //@ts-ignore
          value.replaceAll("-", "").replace(/\s/g, "").length > 10 &&
          value.replaceAll("-", "").replace(/\s/g, "").length < 12
      ),
    })
    .when("typeIdentification", {
      is: "RNC",
      then: Yup.string().test(
        "ci",
        "Formato no es correcto",
        (value: any) =>
          value !== null && //@ts-ignore
          value.replaceAll("-", "").replace(/\s/g, "").length > 8 &&
          value.replaceAll("-", "").replace(/\s/g, "").length < 10
      ),
    })
    .when("typeIdentification", {
      is: "Pasaporte",
      then: Yup.string().test(
        "ci",
        "Formato no es correcto",
        (value: any) =>
          value !== null && //@ts-ignore
          value.replaceAll("-", "").replace(/\s/g, "").length > 7
      ),
    }),
  email: Yup.string().email("Email es invalido"),
  risk: Yup.string()
    .required("Campo es requerido")
    .typeError("Campo es requerido"),
};

export const clientSchema = Yup.object().shape({
  code: Yup.string().required("Campo es requerido"),
  creationtDate: Yup.date().typeError(""),
  creatorUser: Yup.string(),
  executive: Yup.object()
    .shape(executiveSchema)
    .typeError("Campo es requerido"),
  modificationDate: Yup.date().typeError(""),
  modifierUser: Yup.string(),
  personType: Yup.string()
    .required("Campo es requerido")
    .typeError("Campo es requerido"),
  office: Yup.object().shape(branchSchema).typeError("Campo es requerido"),
  typeAccount: Yup.string()
    .required("Campo es requerido")
    .typeError("Campo es requerido"),
  fileForm: Yup.string()
    .required("Campo es requerido")
    .typeError("Campo es requerido"),
  accounts: Yup.array()
    .of(Yup.object().shape(accountSchema))
    .when("typeAccount", {
      is: "Mancomunada",
      then: Yup.array()
        .of(Yup.object().shape(accountSchema))
        .min(2, "Mínimo 2 cuentas"),
    }),
});

export const validate = (value: any) => {
  const matches = value.match(
    /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
  );
  //@ts-ignore
  return matches?.length > 0 || "Not a Number";
};

import { URL } from "../utils/UrlConstant";
import { privateFetch } from "../utils/CustomFetch.js";
import { CONSTANT } from "../utils/Constant";

export const getRequestNote = async (id, page) => {
  let responseData = {};
  await privateFetch
    .get(URL.NOTES_BY_RECORD(id, page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en obtener observaciones del cliente");
    });
  return responseData;
};

export const saveNoteRequest = async (data) => {
  let responseData = {}
  const requestBody = {
    description: data.description,
    createdBy: data.createdBy,
    file: data.file
  }
  await privateFetch
    .post(URL.SAVE_NOTES_BY_RECORD(data.id), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error("Error en guardar observación del usuario")
    })
  return responseData
}

export const saveNoteAndImageRequest = async (data) => { 
  let responseData = {};  
  await privateFetch
    .post(URL.SAVE_NOTES_WITH_IMAGES_BY_RECORD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE3,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}

//throw new Error("Error en guardar observación del usuario")

export const deleteRequestNote = async (data) => {  
  const params = new URLSearchParams([    
    ["user", data.user]
  ]);
  let responseData = {};
  await privateFetch
    .delete(URL.DELETE_NOTES_BY_RECORD(data.id), {params})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en borrar observación del cliente");
    });
  return responseData;
};

export const getRequestImageNoteClient = async (id) => {
  let responseData = {}; 
  let url
  await privateFetch
    .get(
      URL.DOWNLOAD_NOTES_IMAGE(id), { responseType: "blob" }
    )
    .then(async (response) => {
      responseData = await response.data;
      url = window.URL.createObjectURL(new Blob([responseData]));
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
  return url;
};
export const data:any = {
  personType: ["Física", "Jurídica"],
  personTypeLegalTab: ["Nacionales Institucionales", "Autónomos Institucionales", "Nacionales sin fines de lucro",
  "Nacionales Profesionales", "Nacionales no Profesionales","Contrapartes"],
  nacionalesInstitucionales: ["Fondos de Pensiones (AFP)"," Fondos de Inversiones","Puesto de Bolsa","Fiduciaria","Banco Múltiple","Asociaciones de Ahorros y Prestamos"], 
  autonomosInstitucionales: ["Fondo de Pensiones","Fondo de Inversión abierto/cerrado","Fideicomiso"],
  nacionalessinfinDeLucro:["Cooperativa","Organizaciones No Gubernamentales"],
  nacionalesnoProfesionales:["Sociedad Anónima (SA)","Sociedad Anónima Simplificada (SAS)","Sociedad de Responsabilidad Limitada (SRL)","Empresa Individual de Responsabilidad Limitada (EIRL)"],
  contrapartes:["Contraparte Local","Contraparte Extranjera","Contraparte Extranjera que cotiza en Bolsa"],
  status: ["Identificación", "Revisión", "Aprobación", "Calificar", "Hold"] ,  
  step: ["Identificación", "Revisión", "Aprobación", "Calificar", "Hold"],   
  risk: ["Nivel Bajo", "Nivel Medio", "Nivel Alto", "Nivel Alto (PEPS o Vinculado PEPS)"],
  fileForm: ["Físico", "Digital"], 
  typeAccount: ["Mancomunada", "Individual", "Parval Simple"],  
  typeAccountLegal: ["Jurídicas"],  
  stage: [
    { "id": 1 },
    { "id": 6 }
  ],
  office: [
    "Santo Domingo", "Santiago de los Caballeros", "San Francisco de Marcoris", "La Romana"
  ]

}

